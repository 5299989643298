.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 18px 10px;
  border-bottom: 1px solid #ded7f6;
  img {
    margin-right: 10px;
  }
}
.progress {
  width: 200px;
}
.button {
  padding: 0 10px;
  width: auto;
  transition: 1s;
}
