.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.root {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.deleteIcon {
  cursor: pointer;
  transition: 0.3s;
}
.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  h1 {
    font-size: 28px;
    font-weight: 600;
  }
  span {
    color: #8b82a7;
    padding-bottom: 20px;
    font-size: 16px;
  }
  .deleteButtons {
    button {
      font-size: 14px;
      margin-right: 10px;
      transition: 0.3s;
      height: 45px;
    }
  }
}
