.root {
}

.top {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;
  }
  h1 {
    font-size: 18px;
    font-weight: 500;
  }
}

.topSection {
  display: flex;
  margin-top: 40px;
  margin-left: 50px;
  gap: 20px;
  .topButtons {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    button {
      width: max-content;
      padding: 0 20px;
    }
  }
  .name {
    p {
      font-size: 16px;
    }
    span {
      color: #959ba6;
    }
  }
}

.transactionsTable {
  height: 80vh;
  overflow-y: scroll;
  .table {
  }
}

.section {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.investor-toggler {
  background: #ffffff;
  padding: 4px;
  // margin-left: 0px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;
}

.nav-link {
  font-size: 14px;
  line-height: 24px;
  margin-right: 10px;
  display: flex;
  padding: 4px 4px;
  transition: 0.3s;
  justify-content: center;
  border-bottom: 4px solid #ffffff;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: #8b82a7;
}

.nav-link:hover {
  color: #8d6cfa;
}

.active-toggle {
  color: #8d6cfa;
  border-color: #8d6cfa;
}

.singleCompany {
  background: #fff;
  padding: 40px;

  .form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    .cFieldWidth {
      width: 400px;
      margin-bottom: 20px;
    }
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.ruleTitle {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 40px 0;
  gap: 20px;
  hr {
    background-color: #868c98;
    flex: 1;
    border: none;
    height: 0.5px;
  }
  h1 {
    color: #868c98;
    font-size: 16px;
  }
}

.image-uploaded {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
}
.upload-container {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 50%;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}

.table {
  margin-top: 20px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
  align-items: center;
}

.companyHeader {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    :hover {
      opacity: 0.4;
    }
  }
}
