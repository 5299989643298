.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  justify-content: center;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: #16054e;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  span {
    font-size: 20px;
  }
}

.button {
  padding: 8px 24px;
  font-size: 15px;
  width: 200px;
  margin-right: 10px;
  height: 50px;
  margin-top: 100px;
  transition: 0.4s;
  font-weight: 600;
}
.loader {
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 25px;
  }
}
