.container {
	padding: 10px;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		>h1 {
			font-size: 32px;
			color: #16054e;
			font-weight: 500;
		}

		.button {
			font-weight: 600;
			font-size: 14px;
			width: 185px;
			height: 40px;
			margin-left: 22px;
		}
	}

	.section {
		margin-top: 20px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.interest-toggler {
		background: #ffffff;
		padding: 4px;
		width: 100%;
		display: flex;
		padding: 0 30px;
		height: 64px;
	}

	.nav-link {
		font-size: 14px;
		line-height: 24px;
		margin-right: 10px;
		display: flex;
		padding: 4px 20px;
		transition: 0.3s;
		justify-content: center;
		border-bottom: 4px solid #ffffff;
		font-weight: 500;
		align-items: center;
		cursor: pointer;
		color: #8b82a7;
	}

	.nav-link:hover {
		color: #8d6cfa;
	}

	.active-toggle {
		color: #8d6cfa;
		border-color: #8d6cfa;
	}
}

.modal-container {
	h2 {
		color: #16054E;
		font-size: 24px;
		margin: 21px 0;
	}

	.topic-section {
		max-height: 400px;
		overflow-y: scroll;
		margin-top: 44px;

		.topics {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 10px 0;

			.topic-info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				line-height: 40px;

				h4 {
					color: #16054E;
					font-size: 24px;
				}

				img {
					width: 56px;
					height: 56px;
					border-radius: 50%;
				}

				p,
				.field {
					color: #8B82A7;
					font-size: 14px;
					span{
						color: #8D6CFA;
						font-weight: 500;
					}
				}

				.input-number {
					width: 100%;
					border: 1px solid #DED7F6;
					padding: 6px;
					border-radius: 8px;
					background-color: #F8F8FD;
				}
			}

			.topic-name {
				display: flex;
				align-items: center;

				img {
					width: 56px;
					height: 56px;
					border-radius: 50%;
					margin-right: 24px;
				}

				h4 {
					color: #16054E;
					font-size: 16px;
				}
			}
		}

		.primary {
			background-color: #8d6cfa;
			color: #ffffff;
			padding: 8px 32px;
			border-radius: 16px;
		}

		.primary:hover {
			background-color: #ffffff;
			color: #8d6cfa;
			border: 1px solid #8d6cfa;
		}
	}

	.topic-button {
		width: 100%;
		height: 60px;
		padding: 16px 0;
		font-size: 24px;
	}
}