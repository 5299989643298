.container {
  padding: 40px 20px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    h1 {
      color: #8b82a7;
    }
    span {
      color: #16054e;
    }
  }
  .content {
    padding: 40px;
    margin-top: 30px;
    background-color: #ffffff;
  }
  .form {
    margin-top: 40px;
  }
}

.field {
  margin-top: 20px;
  width: 80%;
}

.button {
  padding: 5px 10px;
  margin-top: 10px;
  font-size: 17px;
  height: 45px;
  width: 200px;
  transition: 0.3s;
  font-weight: 500;
}

.related-topics {
  margin-top: 50px;
  h1 {
    font-size: 24px;
  }
}

.takeaway-container {
  margin-top: 50px;
  h1 {
    font-size: 24px;
  }
  .take-away {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-left: 5px;
      cursor: pointer;
      margin-bottom: 5px;
    }
  }
}

.date-format {
  justify-content: space-between;
  width: 76%;
  display: flex;
  flex-direction: row;
}

.takeaway-list {
  width: 75%;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 4px;
  min-height: 30px;
  margin-bottom: 10px;
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.quill-container {
  margin: 30px 0;
}

.quill {
  width: 730px;
  min-height: 100px !important;
}
