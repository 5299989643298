.header {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.form {
  background: #fff;
  padding: 40px;
}
