.ant-switch-checked {
  background-color: #8d6cfa;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #8d6cfa;
  border-color: #8d6cfa;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #8d6cfa;
}

.ant-radio-button-wrapper:hover {
  color: #8d6cfa;
}
