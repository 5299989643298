.analog {
  .container {
    padding: 25px;
    background: #fff;
    h2 {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  .select-input {
    width: 500px;
  }
  .delete-button {
    color: red !important;
    padding: 10px 20px;
    font-size: 15px;
    width: 200px;
    height: 50px;
    transition: 0.4s;
    border-color: red;
    font-weight: 500;
    margin: 20px;
  }
  .delete-button:hover {
    background-color: red;
    color: white !important;
  }
  .c-image-uploaded {
    width: 240px;
    height: auto;
    img {
      width: 240px;
      height: 240px;
      border-radius: 16px;
    }
  }
  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .c-company-upload {
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ede9fa;
    border-radius: 16px;
    div {
      width: 60%;
      font-size: 16px;
      text-align: center;
      color: #16054e;
      font-weight: 400;
      margin-top: 16px;
      span {
        color: #8b82a7;
      }
    }
  }
  .card-container {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 30px;
    border-radius: 16px;
    width: 85%;
    padding: 20px 0;
    justify-content: space-evenly;
  }

  .name-input {
    width: 500px;
  }
  .form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    margin: 20px 0;
    cursor: pointer;
    h1 {
      color: #8d6cfa;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .loading {
    display: flex;
    flex-direction: column;
    margin-top: 259px;
    justify-content: center;
    align-items: center;
  }
  .input-number {
    width: 500px;
    padding: 4px;
    border-radius: 8px;
    background-color: #f8f8fd;
    border: 1px solid #ded7f6;
    &:hover,
    &:focus {
      border-color: #8d6cfa;
    }
  }
  .warning {
    color: red;
    font-size: 14px;
    font-weight: 450px;
  }
}
