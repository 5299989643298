@import 'globalstyles/mixings';
@import 'globalstyles/_variables.scss';
.welcome-section {
  h1 {
    @include title;
  }

  p {
    @include subTitle;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.line {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid #ded7f6;
  text-align: center;
  margin-bottom: 20px;

  span {
    font-size: 20px;
    background: $secondary;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-flex;
    color: #8b82a7;
  }
}

.form-container {
  width: 100%;
}
.password-text {
  color: #16054e;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.account-text {
  margin-top: 10px;
  font-size: 0.9rem;
  a {
    text-decoration: none;
    color: $primary;
  }
}

.check-input-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;

  .terms {
    margin-left: 6px;
    font-size: 0.7rem;
    color: #16054e;
    font-weight: 500;

    a {
      text-decoration: none;
      color: $primary;
    }
  }
  .error {
    color: $error;
  }
}
.error {
  font-size: 0.8rem;
  color: $error;
}

.field {
  margin-bottom: 20px;
}

.form {
  margin-top: 100px;
}
