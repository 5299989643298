.container {
  height: max-content;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.date-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  justify-content: space-around;
  position: relative;
}
.filter {
  display: flex;
  flex-direction: row;
  border-radius: 32px;
  border: 1px solid #ded7f6;
  padding: 5px 15px;
  cursor: pointer;
  align-items: center;
  span {
    color: #16054e;
    font-size: 14px;
    font-weight: 500;
  }
  img {
    width: 15px;
    height: 10px;
    margin-left: 5px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.date-style {
  color: #8b82a7;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8.5px;
}

.sections-tab {
  margin-top: 40px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  h1 {
    color: #16054e;
    font-size: 24px;
  }
}

.toggler {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 4px 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-tab {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.percentage-boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
}
.campaign-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 42px;
  margin-bottom: 42px;
  align-items: center;
  h1 {
    color: #16054e;
    font-size: 24px;
  }
}
.right-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: 15px;
    margin-right: 15px;
    color: #16054e;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 10px;
      cursor: pointer;
      transition: 0.3s;
    }
    img:hover {
      opacity: 0.3;
    }
  }
}
.cardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filter-comp {
  height: 0px;
  overflow: hidden;
  z-index: 99;
  top: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  right: 0px;
  transition: height ease 0.3s;
  position: absolute;
}
.active-campaign {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0;
  align-items: flex-start;
  height: 800px;
  overflow-y: scroll;
}
.filter-overlay {
  background-color: #ffffff;
}
.no-campaign {
  display: flex;
  justify-content: center;
  margin: 40px;
  width: 100%;
  height: 200px;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  h1 {
    color: #16054e;
    font-weight: 500;
    font-size: 28px;
  }
}

.campaign-section {
  min-height: max-content;
}
