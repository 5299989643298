.card-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ded7f6;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  transition: height ease 0.3s;
  border-radius: 16px;

  margin: 20px 0;
  .container {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    transition: height ease 0.3s;
    flex-direction: row;
  }
  .right-grid {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    margin-left: 10px;
    img {
      margin-right: 10px;
    }
  }
}

.bottom-view {
  margin-top: 20px;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: height ease 0.3s;
  justify-content: space-between;
}
.content {
  height: 0px;

  overflow: hidden;
  transition: height ease 0.3s;
}
.gray-text {
  color: #8b82a7;
  font-size: 16px;
}
.news-image {
  height: 150px;
  width: 150px;
  margin-right: 20px;
}
.add-class {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  h3 {
    font-size: 15px;
    margin-right: 12px;
  }
  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    span {
      color: #8d6cfa;
      font-size: 16px;
      font-weight: bold;
    }
    img {
      height: 22px;
      margin-right: 5px;
      width: 22px;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 25px;

    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-button {
    font-size: 18px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}

.view-all {
  margin: 20px 0;
  span {
    color: #8d6cfa;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
}

.view-all-comp {
  height: 0px;
  overflow: hidden;
  z-index: 99;
  top: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  right: 0px;
  transition: height ease 0.3s;
  position: absolute;
}
.select-btn {
  border: 1px solid #ded7f6;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  padding: 10px 5px;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  .select {
    font-size: 16px;
  }
}

.trash {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.card {
  padding: 5px 10px;
  border: 1px solid #8b82a7;
  border-radius: 50px;
  margin: 0 5px;
  color: #8d6cfa;
}

.link {
  display: block;
  color: #8b82a7;
  font-size: 16px;
}
.link:hover {
  color: #8d6cfa;
  text-decoration: underline;
}
