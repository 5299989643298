.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 15px;
    width: 24px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.al-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  .delete-icon {
    margin-left: 20px;
    height: 25px;
    width: 25px;
  }
}

.field {
  margin-bottom: 15px;
}

.body {
  padding: 40px;
}
.title {
  font-size: 32px;
  color: #16054e;
  font-weight: 500;
  margin-bottom: 24px;
}
.container {
  background: #ffffff;
  padding: 5px 0 30px 30px;
}

.content {
  margin-top: 80px;
}

h2 {
  font-size: 18px;
}

.input-number {
  width: 290px;
  padding: 4px;
  border-radius: 8px;
  height: 45px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
