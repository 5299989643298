.section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}
img {
  cursor: pointer;
}
.input-width {
  max-width: 340px !important;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.addButton {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: row-reverse;
}

.all {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  button {
    width: max-content;
    padding: 5px 10px;
    transition: 0.5s ease-in-out;
  }
}
