.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 24px;
    }
  }
  .buttons {
    margin-top: 40px;
    gap: 20px;
    display: flex;
  }
}

.field {
  margin-bottom: 20px;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  h1 {
    font-size: 24px;
  }
}
