.startup-table {
  width: 90%;
  margin-top: 20px;
  min-height: 600px;
  margin-left: 20px;
}
.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.record-name {
  display: flex;
  flex-direction: row;
  min-width: 100px !important;
  cursor: pointer;
}

.percentage-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 40px 0;
  gap: 20px;
}
.h1 {
  margin-bottom: 10px;
}
.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80px;
  padding: 0 6px;
  .tooltip {
    visibility: hidden;
    background-color: #8b82a7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 80px;
    cursor: pointer;
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.record-label:hover .tooltip {
  visibility: visible;
}

.modal-container {
  h1 {
    margin-bottom: 20px;
  }
}

.table-container {
  background: #ffffff;
  width: 100%;
  margin-top: 10px;
  padding-top: 20px;
  .header {
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 24px;
    }
  }
}
.button-wrapper {
  margin-right: 20px;
}
.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 250px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}
.no-updates {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  h1 {
    color: black;
    font-size: 20px;
    font-weight: 600;
  }
  img {
    width: 327px;
    height: 230px;
  }
  span {
    text-align: center;
    margin: 20px 0;
    width: 50%;
  }
}

.add-single {
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
  align-items: center;
  .image-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    div {
      .header {
        font-size: 18px;
        margin-bottom: 2px;
      }
      span {
        color: #8b82a7;
      }
    }

    img {
      width: 50px;
      margin-right: 20px;
      height: 50px;
    }
  }
}

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  span {
    margin-bottom: 10px;
    text-align: center;
    width: 80%;
    color: #8b82a7;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.modal-loading {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.startup-max-height {
  max-height: 600px;
  min-height: 600px;
  overflow-y: scroll;
}

.actions {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 150px;
  justify-content: space-between;
  align-items: center;
  .action-icon {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    color: #8b82a7;
    img {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .action-icon:hover {
    opacity: 0.8;
  }
}
.user-table {
  overflow-x: scroll;
}
