.container {
  padding: 10px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      font-size: 32px;
      color: #16054e;
    }
    .right-grid {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 15px;
        margin-right: 15px;
        color: #16054e;
      }
      .button {
        padding: 0 10px 0 0;
        width: 150px;
        height: 45px;
        font-size: 15px;
        font-weight: 600;
        transition: 0.3s;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          margin-right: 10px;
          cursor: pointer;
          transition: 0.3s;
        }
        img:hover {
          opacity: 0.3;
        }
      }
    }
  }
}

.skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
  margin: 20px;
}
.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  width: auto;
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  transition: 0.3s;
  font-weight: 500;
}
.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  flex: 1;
  font-weight: bold;
  border-radius: 50%;
}
.investor-header {
  margin-bottom: 40px;
}
.percentage-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 40px 0;
  gap: 20px;
}
.verifier-toggler {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 4px 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-tab {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.sections-tab {
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.users-section {
  margin: 30px 0;
  h1 {
    font-size: 24px;
    color: #16054e;
    margin-bottom: 0;
  }
}

.users-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0;

  padding: 0 10px;
  flex-wrap: wrap;
  max-height: 600px;
  overflow-y: scroll;
}
.investors-header-grid {
  display: flex;
  margin: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.active-dropdown {
  padding: 10px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #90a0c2;
  span {
    margin-right: 5px;
  }
}
.user-table {
  width: 100%;
  position: relative;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}
.download-icon {
  margin-right: 30px;
}
.title {
  width: 22%;
  font-size: 18px;
  font-weight: 450;
}
.modal-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userDownloadModal {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.download-icon {
  font-size: 25px;
  color: #8b82a7;
  cursor: pointer;
  transition: 0.3s;
}

.download-icon:hover {
  color: #8d6cfa;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  h1 {
    margin-bottom: 10px;
  }
  span {
    color: #8b82a7;
    font-size: 15px;
    text-align: center;
  }
  .modalText {
    color: #8d6cfa;
    font-weight: 500;
  }
}

.search-bar {
  border: 1px solid rgb(228, 213, 228);
  border-radius: 8px;
}

.loadingSkeleton {
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0.3) 75%,
    rgba(255, 255, 255, 0.6) 70%,
    rgba(255, 255, 255, 0.7) 95%
  );
  background-size: 400% 100%;
  animation: shimmer 1.5s ease-in-out infinite;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 10px;
  width: 230px;
  height: 300px;
}

.circle {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: black;
}

.rect {
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.filters {
  display: flex;
  align-items: center;
  position: relative;
  gap: 20px;
}
.filter {
  margin-left: 20px;
  display: flex;
  padding: 8px 16px !important;
  height: 50px;
  cursor: pointer;
  align-items: flex-start;
  gap: 8px;
  flex-direction: row;
  transition: 0.5s ease-in-out;
  border: 1px solid #ded7f6;
  border-radius: 32px;
  align-items: center;
  h2 {
    color: #16054e;
    font-size: 14px;
    font-style: normal;
    margin: 0;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }
}

.filterModal {
  position: absolute;
  background: #fff;
  padding: 20px;
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 500;
  width: 550px;
  right: 0;
  top: 100%;
}
