.bank-container {
  background: #ffffff;
  padding: 30px;
}

.active-bank-card {
  border: 2px solid #8d6cfa !important;
}

.bank-card,
.active-bank-card {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 104px;
  border: 1px solid #ded7f6;
  border-radius: 8px;
  .card-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .image {
      img {
        width: 56px;
        height: 56px;
        margin-right: 15px;
      }
    }
    .body {
      h2 {
        font-size: 16px;
        font-weight: 500;
        color: #16054e;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #8b82a7;
      }
    }
  }
}
.default {
  width: 30% !important;
  .active {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 15px;
    }
    h3 {
      font-size: 14px;
      color: #16054e;
    }
  }
}
.bank-card-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  align-items: center;
}
.card-header {
  margin-bottom: 40px;
  h1 {
    margin-bottom: 15px;
  }
}
