.card {
	background-color: #fff;
	border-radius: 16px;
	padding: 24px;
	border: 1px solid #ded7f6;
	cursor: pointer;
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		.image {
			width: 48px;
			height: 48px;
			border-radius: 50%;
		}

		.title {
			font-size: 18px;
			font-weight: 400;
			margin-left: 16px;
			color: #16054e;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
		}
	}

	.body {
		margin-top: auto;
		margin-bottom: 26px;

		> h3 {
			font-size: 21px;
			font-weight: 500;
			color: #16054e;
			margin-bottom: 4px;
		}

		.date {
			font-size: 10px;
			color: #8b82a7;
		}
	}

	.footer {
		display: flex;
		margin-top: auto;
		justify-content: space-between;
		align-items: center;

		> button {
			padding: 0px;
			background-color: transparent;
			display: flex;
			align-items: center;

			&:first-of-type {
				.icon {
					width: 20px;
					height: 20px;
				}
			}

			.icon {
				width: 17px;
				height: 17px;
				margin-right: 9px;
			}

			> span {
				color: #8b82a7;
				font-size: 14px;
			}
		}
	}
}
