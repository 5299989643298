.container {
  background: #ffffff;
  padding: 10px 0 20px 20px;
}
.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 40px 10px 0 10px;
}
.title {
  width: 50%;
  margin-top: 40px;
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
  span {
    color: #8b82a7;
    font-weight: 400;
    font-size: 14px;
  }
}
.upload {
  max-width: 796px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  span {
    font-size: 24px;
    font-weight: 500;
    color: #16054e;
  }
}
.input-file {
  visibility: hidden;
}
.pdf-title {
  margin: 40px 0;
}

.pdf-height {
  max-height: 100vh;
  overflow-y: scroll;
}

.number {
  width: 310px;
  padding: 4px;
  border-radius: 8px;
  height: 45px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}

.formField {
  margin-bottom: 20px;
}

.bottom-buttons {
  margin: 40px 0;
  display: flex;
  gap: 20px;
}
