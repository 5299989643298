.container {
  background: #fff;
  padding: 30px;
  h1 {
    margin-bottom: 40px;
  }
}
.field-item {
  width: 500px;
  margin: 35px 0;
}
