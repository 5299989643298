.video-container {
  margin-top: 30px;
  h1 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.upload-container {
  width: 700px;
  cursor: pointer;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ede9fa;
  border-radius: 16px;
  div {
    font-size: 18px;
    font-weight: 500;
  }
}
.player {
  border-radius: 16px;
  width: 700px;
  height: 400px;
}
.upload-buttons {
  margin: 20px 0;
  .change {
    padding: 10px 25px;
    font-size: 15px;
    width: 200px;
    margin-right: 15px;
    height: 40px;
    transition: 0.4s;
    font-weight: 500;
  }
  .delete {
    color: red !important;
    padding: 10px 25px;
    font-size: 15px;
    width: 200px;
    height: 40px;
    transition: 0.4s;
    border-color: red;
    font-weight: 500;
  }
  .delete:hover {
    background-color: red;
    color: white !important;
  }
}
