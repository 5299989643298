.navigation {
  display: flex;
  align-items: center;
  flex-direction: row;
  img {
    margin-right: 30px;
    cursor: pointer;
  }
  span {
    font-size: 28px;
    font-weight: 500;
    color: #8b82a7;
    .h-style {
      color: #16054e;
    }
  }
}

.nav {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.active,
.disable {
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px;
}

.active {
  background-color: #8d6cfa;
  cursor: pointer;
}

.disable {
  background-color: #a48afa;
  cursor: not-allowed;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.pdfviewer {
  margin-top: 20px;
  height: 85vh;
  margin: 10px auto 0 auto;

  :global(.rpv-core__page-layer) {
    width: 100% !important;
  }
}
