.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.header {
  margin: 40px 0;
  h1 {
    color: #16054e;
    font-size: 32px;
    font-weight: 500;
  }
}
.title {
  color: #16054e;
  font-size: 21px;
  margin-bottom: 28px;
  font-weight: 500;
}
.risks {
  margin-bottom: 56px;
}
.container {
  background: #ffffff;
  padding: 10px 0 30px 40px;
}

.form-item {
  margin-bottom: 20px;
}
