@import "../../globalstyles/variables";

.layout {
  width: 100%;

  .backdrop {
    position: fixed;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.4s ease-in-out;

    @media screen and (min-width: 1201px) {
      display: none;
    }

    &.show {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }
  }

  .header {
    background: $secondary;
    box-shadow: $shadow;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2300;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 16px;

    .logo {
      transition: opacity 0.7s ease-in-out;
      opacity: 1;
      visibility: visible;
      pointer-events: all;

      > * {
        width: 80px;

        img {
          object-fit: contain;
        }
      }

      &.hide {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }

    @media screen and (min-width: 1201px) {
      display: none;
    }
  }

  .main {
    background: $main;
    margin-left: 220px;
    min-height: 100vh; // for demo purposes
    padding: 2rem;

    @media screen and (max-width: 1200px) {
      margin-left: 0px;
      margin-top: 50px;
    }
  }
}

