@import '../../../globalstyles/variables';

.assetClassForm {
  margin-top: 20px;
  background: #fff;
  padding: 40px;
  .fieldWidth {
    width: 700px;
    margin-bottom: 20px;
  }
  .space {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    border-radius: 16px;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 700px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .listField {
      width: 500px;
      margin-bottom: 5px;
    }
  }
  .delete {
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
}

.instrumentHeader {
  .section {
    margin: 20px 0;
    width: 100%;
    display: flex;
    padding: 20px;
    background: #fff;
    align-items: center;
    gap: 10px;
    overflow: auto;
    // justify-content: center;
  }

  .investor-toggler {
    background: #ffffff;
    padding: 4px;
    // margin-left: 0px;
    width: 100%;
    display: flex;
    padding: 0 10px;
    height: 64px;
    overflow-x: scroll;
  }
}

.navLink {
  display: flex;
  align-items: center;
  font-size: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px 19px;
  margin: 20px 0;
  background: #fff;

  div {
    cursor: pointer;
    :hover {
      color: $primary;
    }
  }

  gap: 25px;
  .active {
    background: $primary;
    color: $secondary;
    border-radius: 8px;
    padding: 10px 8px;
    font-weight: 700;
    margin: 10px 0;

    svg {
      path {
        fill: $secondary;
      }
    }
  }

  svg {
    margin-right: 19px;
    height: 22px;
    width: 22px;
  }
}

.header {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
}
