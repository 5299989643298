.container {
  background-color: #ffffff;
  margin-top: 30px;
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    flex-direction: row;
    h1 {
      font-size: 24px;
      color: #16054e;
    }
    .button {
      padding: 10px 25px;
      font-size: 15px;
      width: 200px;
      height: 40px;
      margin-top: 10px;
      margin-right: 7px;
      transition: 0.4s;
      font-weight: 500;
    }
  }
  h2 {
    color: #16054e;
    font-size: 18px;
    margin: 20px;
  }
  .info-dropdown {
    border: none;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    padding: 5px 20px;
    flex-direction: row;
    width: 100%;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}
.dropdown {
  width: 60%;
  margin: 20px;
}
