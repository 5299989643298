.container {
  background: #fff;
  padding: 30px;
}
.form {
  margin: 20px 0;
}
.field-width {
  max-width: 600px;
  margin-bottom: 25px;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
