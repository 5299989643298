.nav-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 40px;
  padding-top: 40px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.founder-container {
  width: 95%;
  margin-top: 30px;
  margin-left: 20px;
  border-radius: 4px;
  padding: 30px;
  background-color: #ffffff;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    h1 {
      font-size: 22px;
      font-weight: 500;
      color: #16054e;
    }
    span {
      font-size: 24px;
      font-weight: 500;
      color: #16054e;
    }
  }
}

.button-wrapper {
  margin: 40px 0;
  .button {
    padding: 10px 25px;
    font-size: 15px;
    width: 200px;
    height: 40px;
    margin-top: 10px;
    transition: 0.4s;
    font-weight: 500;
    margin-right: 20px;
  }
}

.add-founder {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}
