.button {
  width: auto;
  text-align: center;
  padding: 0 15px !important;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s;
}
.download-icon {
  font-size: 25px;
  color: #8b82a7;
  cursor: pointer;
  transition: 0.3s;
}

.download-icon:hover {
  color: #8d6cfa;
}
