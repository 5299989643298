.whatsappNotification {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.titlePreview {
  margin-bottom: 20px;
}

.inputClassName,
.preview {
  margin-bottom: 10px;
}

.tabs {
  margin-top: 24px;
}
