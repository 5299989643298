.container {
  margin-top: 16px;
  border-radius: 8px;
  background-color: #fff;
  padding: 36px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      color: #16054e;
      font-size: 24px;
      font-weight: 500;
    }

    .actions {
      display: flex;
      align-items: center;

      .search {
        input {
          background-color: #fff;
        }
      }

      .button {
        padding: 0 10px 0 0;
        width: 150px;
        height: 40px;
        font-size: 15px;
        font-weight: 600;
        transition: 0.3s;
        margin-left: 16px;
      }
    }
  }

  .report-grid {
    display: grid;
    margin-top: 50px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 24px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% !important;
  margin-left: 20rem;
  align-items: center;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}
