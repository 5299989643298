.top {
  display: flex;
  margin-bottom: 40px;
  gap: 20px;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.rate {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  .values {
    margin-bottom: 10px;
    p {
      font-weight: bold;
      color: green;
    }
    span {
      color: black;
      padding-left: 10px;
      font-size: 15px;
    }
  }
}

.modal {
  h1 {
    margin-bottom: 30px;
    font-size: 26px;
  }
}

.nav-buttons {
  margin: 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin-bottom: 20px;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.create {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  position: relative;
  button {
    width: max-content;
    padding: 8px 19px;
    transition: 0.3s ease-in-out;
    margin-bottom: 20px;
  }
}
.field {
  margin-bottom: 20px;
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
  .sortHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    h1 {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }
    p {
      font-weight: bold;
      border-radius: 50%;
      cursor: pointer;
      font-size: 12px;
      border: 1px solid #b0b0b0;
      padding: 8px 13px;
      transition: 0.5s ease-in-out;
      margin-bottom: 10px;
    }
    p:hover {
      opacity: 0.5;
    }
  }
}
