.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.body {
  margin-top: 30px;
  background-color: #ffffff;
  h2 {
    margin-bottom: 10px;
    text-align: center;
  }
}

.users-table {
  width: 100%;
  overflow-x: scroll;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.width {
  max-width: 700px;
}

.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}
.record-name {
  display: flex;
  flex-direction: row;
  min-width: 100px !important;
  cursor: pointer;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

.action-buttons {
  margin-bottom: 40px;
  display: flex;
  width: 80%;
  justify-content: right;
  gap: 15px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-button {
    font-size: 18px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  justify-content: center;
  align-items: center;
}

.input-number {
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
}
