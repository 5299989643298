.social label {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}
.button {
  transition: 0.3s;
  width: 150px;
  height: 45px;
  font-size: 15px;
}
.button-red {
  color: red !important;
  transition: 0.4s;
  width: 150px;
  height: 45px;
  font-size: 15px;
  margin-left: 10px;
  border-color: red;
}
.button-red:hover {
  background-color: red;
  color: white !important;
}
.social-container {
  margin: 40px 0 0 20px;

  padding: 30px;
  background: #ffffff;
  h1 {
    margin-bottom: 20px;
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}

.not-found {
  font-size: 32px;
  margin-top: 30px;
  font-weight: 500;
}
.form-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  width: 90%;
  justify-content: space-between;
}
