.root {
}

.routes {
  display: flex;
  background: #fff;
  padding: 20px;
  margin: 20px 0;
}

.currencyCard {
  width: 350px;
  height: 140px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #3a2196;
  padding: 20px;
  gap: 10px;
  p {
    font-size: 16px;
    color: #eeeeee;
    font-weight: 500;
  }
  h1 {
    font-size: 32px;
    font-weight: 500;
    color: #fff;
  }
}

.loadingSkeleton {
  background: linear-gradient(
    -90deg,
    rgba(22, 6, 80, 0.3),
    rgba(55, 0, 255, 0.6),
    rgba(40, 12, 142, 0.7)
  );
  background-size: 400% 100%;
  animation: shimmer 1.5s ease-in-out infinite;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 350px;
  height: 140px;
}

.cards {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.cardsRoot {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.filters {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
  align-items: center;
  gap: 10px;
}

.form {
  display: flex;
  height: 40px;
  gap: 10px;
  width: 100%;
}

.exportModal {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 22px;
    text-align: center;
  }
  p {
    margin-bottom: 20px;
  }
}

.field {
  width: 300px;
  margin-bottom: 15px;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -150% 0;
  }
}
