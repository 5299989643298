.container {
  background: #fff;
  padding: 30px;
  .header {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 25px;
    }
    .button {
      padding: 8px 24px;
      font-size: 15px;
      width: auto;
      margin-right: 10px;
      height: 40px;
      margin-top: 10px;
      transition: 0.4s;
      font-weight: 500;
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.record-name {
  display: flex;
  flex-direction: row;
  min-width: 100px !important;
  cursor: pointer;
}
.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}
.image {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 20px;
  }
}
.field-width {
  max-width: 730px;
}
.modal-content {
  h2 {
    margin-bottom: 20px;
    text-align: center;
    vertical-align: center;
  }
}
