.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  p {
    align-items: center;
    text-align: center;
  }
}

.form {
  margin-top: 40px;
}
