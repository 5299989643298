.button {
  font-size: 15px;
  width: 200px;
  height: 40px;
  transition: 0.4s;
  color: white;
  border-radius: 100px;
  margin-bottom: 20px;
  font-weight: 500;
  background-color: #8d6cfa;
  border: 1px solid #8d6cfa;
  transition: 0.4s;
}
.secondary {
  font-size: 15px;
  width: 200px;
  height: 40px;
  transition: 0.4s;
  color: #8d6cfa;
  border-radius: 100px;
  margin-bottom: 20px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #8d6cfa;
  transition: 0.4s;
}

.small-width {
  font-size: 14px;
  padding: 5px 16px;
  transition: 0.4s;
  color: #8d6cfa;
  border-radius: 100px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #8d6cfa;
  transition: 0.4s;
}

.button:hover {
  background-color: #ffffff;
  color: #8d6cfa;
}
.secondary:hover {
  background-color: #8d6cfa;
  color: #fff;
}
.small-width:hover {
  background-color: #8d6cfa;
  color: #fff;
}

.button:focus {
  border: 1px solid #8d6cfa;
}

.db-disabled-btn:hover {
  color: white;
  background: #8d6cfa;
  border: 1px solid #8d6cfa;
}

.db-disabled-btn {
  opacity: 0.4;
}

.db-disabled-btn:hover {
  cursor: not-allowed;
}