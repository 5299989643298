.nav {
  h1 {
    font-size: 25px;
    font-weight: 800;
    text-transform: uppercase;
  }
}

.table {
  margin-top: 30px;
  button {
    float: right;
    margin-bottom: 20px;
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 25px;

    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-button {
    font-size: 18px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}

.bigWidth {
  width: 300px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.columnButton {
  padding: 0 !important;
  margin: 0 !important;
  width: 100px;
  button {
    margin: 0;
  }
}
