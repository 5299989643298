.add-founder {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}
.input-width {
  max-width: 300px !important;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  margin: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  .left-grid {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .right-grid {
    width: 70%;
    margin-bottom: 10px;
    .form {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
.container {
  background: #ffffff;
  padding: 20px;
}

.title {
  color: #16054e;
  font-size: 22px;
  font-weight: 500;
  margin: 34px 0;
}

.header {
  color: #16054e;
  font-size: 32px;
  font-weight: 500;
  margin: 40px 0;
}

.founder-ceo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.delete-founder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: right;
  cursor: pointer;
  div {
    display: flex;
    flex-direction: row;
  }
  span {
    color: red;
    font-size: 15px;
    font-weight: 500;
  }
  h6 {
    margin-right: 20px;
    color: #8d6cfa;
    font-size: 20px;
    font-weight: 600;
  }
}

.button {
  margin-right: 10px;
}
.founder-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delete-button {
  color: red !important;
  padding: 10px 20px;
  font-size: 15px;
  width: 200px;
  height: 50px;
  transition: 0.4s;
  border-color: red;
  font-weight: 500;
  margin: 20px;
}
.delete-button:hover {
  background-color: red;
  color: white !important;
}

.c-image-uploaded {
  width: 240px;
  height: auto;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.c-company-upload {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}
