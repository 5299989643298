.root {
  width: 300px;
  margin-right: 35px;
  margin-bottom: 40px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  img {
    height: 150px;
    width: 300px;
    margin-bottom: 10px;
  }
}
.footer {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  .action-icon {
    display: flex;
    cursor: pointer;
    margin-right: 20px;
    flex-direction: row;
    img {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .action-icon:hover {
    opacity: 0.8;
  }
}
.modal-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 25px;

    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-button {
    font-size: 18px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}

.title {
  padding: 0 10px;
  h1 {
    font-size: 18px;
  }
}
.content {
  padding: 10px;
  span {
    font-size: 12px;
  }
}
