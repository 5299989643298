.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.record-name {
  display: flex;
  flex-direction: row;
  min-width: 100px !important;
  cursor: pointer;
}

.active-status {
  background: #eaf9ea;
  color: #25ad25;
  padding: 5px 13px;
  border-radius: 16px;
}

.inactive-status {
  background-color: #f6f6f9;
  color: #5c5083;
  padding: 5px 10px;
  border-radius: 16px;
}

.container {
  background: #ffffff;
  padding: 20px;
  .header {
    h1 {
      margin: 20px 0;
    }
  }
}
.user-table {
  overflow-x: scroll;
}
.search-bar {
  margin-bottom: 25px;
}
