.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .h-style {
    color: #8b82a7;
    font-size: 24px;
    font-weight: 500;
    margin-right: 5px;
  }
  span {
    font-size: 24px;
    font-weight: 500;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}
.header-text {
  color: #16054e;
  font-size: 20px;
  margin: 30px 0;
  font-weight: 600;
}
.header-subtext {
  color: #16054e;
  font-size: 16px;
  margin: 15px 0;
  font-weight: 600;
}
.settings-grid {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.main-content {
  background: #ffffff;
  margin: 40px;
  padding: 40px;
  h1 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
  }
}

.form {
  margin-top: 30px;
}
.width {
  width: 730px;
  margin-bottom: 20px;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 94%;
  margin-top: 30px;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.smallWidth {
  width: 230px;
  margin-bottom: 20px;
}

.input-number {
  width: 230px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
}
