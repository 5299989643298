.container {
  background: #ffffff;
  padding: 20px 30px;
}
.title {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
