.modal {
  height: auto;
  text-align: center;
  .type {
    color: red;
    font-weight: bold;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 40px;
  }
  button {
    width: auto;
    padding: 8px 16px;
  }

  span {
    text-align: center;
    font-size: 16px;
  }
}
.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
  button {
    width: 150px;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}

.emails {
  max-height: 400px;
  overflow: auto;
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: max-content;
  margin-bottom: 20px;
  margin-left: 25px;
  padding: 5px;
  border-radius: 8px;
  h5 {
    margin-left: 5px;
  }
}

.emailField {
  display: flex;
  gap: 20px;
  align-items: center;
  .emailWidth {
    width: 400px;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
