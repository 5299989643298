.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  img {
    cursor: pointer;
    margin-right: 10px;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 30px;
    margin-right: 10px;
    border-radius: 50px;
    height: 30px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.table {
  background: white;
}
.user-table {
  overflow-x: scroll;
}
