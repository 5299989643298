.root {
  display: flex;
  flex-direction: column;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.image-section {
  display: flex;
  gap: 40px;
}

.content {
  background-color: #fff;
  padding: 40px 20px;
  margin-top: 40px;
  h1 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.field {
  width: 500px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
  width: 80%;
}

.fees {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  margin-bottom: 20px;
  padding: 20px;
  margin-bottom: 80px;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: rgba(158, 136, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  width: 100%;
  img {
    cursor: pointer;
  }
  img:hover {
    opacity: 0.5;
  }
}

.add {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #8d6cfa;
  font-weight: 500;
  text-transform: uppercase;
  img {
    width: 20px;
    height: 20px;
  }
}

.add-button {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: 140px;
  align-items: center;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
  border-radius: 8px;

  img {
    margin-right: 10px;
  }
  :hover {
    opacity: 0.5;
  }
}

.delete-button {
  color: red !important;
  padding: 10px 20px;
  font-size: 15px;
  width: 200px;
  height: 50px;
  transition: 0.4s;
  border-color: red;
  font-weight: 500;
  margin: 20px;
}
.delete-button:hover {
  background-color: red;
  color: white !important;
}
.c-image-uploaded {
  width: 240px;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-company-upload {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}
.card-container {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 30px;
  border-radius: 16px;
  width: 85%;
  padding: 20px 0;
  justify-content: space-evenly;
}

.scheduleFees {
  display: flex;
  flex-direction: column;
  width: 600px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 20px;
  border-radius: 8px;
  margin-top: 20px;
  padding: 0 20px;
  div {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
}

.code {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.direction {
  width: 370px;
}

.feesDropdown {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
  gap: 20px;
}

.minNumber {
  width: 260px;
  height: 40px;
  border-radius: 8px;
  background: #f8f8fd;
}

.infoModal {
  align-self: center;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  button {
    padding: 0 20px;
    width: max-content;
    font-size: 14px;
    font-weight: 500;
  }
}

.modalRoot {
  h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 40px;
    text-align: center;
  }
  .modalField {
    width: 500px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

.fee-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  img {
    width: 20px;
    height: 20px;
  }
}

.fee-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
}

.feeNumber {
  width: 250px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
  background: #f8f8fd;
}

.collapseHeader {
  margin: 0;
  padding: 0;
  display: flex;
  width: max-content;
}

.collapse {
  border: none !important;
}
