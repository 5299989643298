.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 20px;
    cursor: pointer;
    width: 15px;
  }
}

.formSection {
  padding: 20px;
  margin-top: 40px;
  background: #fff;
}

.form {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.date {
  width: 380px;
  margin-bottom: 15px;
}

.inputWidth {
  width: 380px;
  margin-bottom: 35px;
}
.bigWidth {
  width: 100%;
  margin-bottom: 20px;
}
.uploadImage {
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  margin-bottom: 40px;
}

.inputNumber {
  width: 380px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
