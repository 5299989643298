.record-label {
  display: flex;
  flex-direction: row;
  width: 100px;
  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.active-status {
  background: #eaf9ea;
  color: #25ad25;
  padding: 5px 13px;
  border-radius: 16px;
}

.inactive-status {
  background-color: #f6f6f9;
  color: #5c5083;
  padding: 5px 10px;
  border-radius: 16px;
}
.button {
  padding: 10px 25px;
  font-size: 15px;
  width: auto;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.container {
  background: #ffffff;
  padding: 20px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      margin: 20px 0;
    }
  }
  .payment-method {
    display: flex;
    justify-content: center;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-container {
  height: auto;
  h1 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-button {
    font-size: 18px;
    margin-top: 30px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}

.dropdown-btn {
  margin-left: 24px;
  background-color: #fff;
  border: 1px solid #ded7f6;
  border-radius: 32px;
  width: 134px;
  height: 40px;
  color: #16054e;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
