/** OVERIDING ANTD AND VIDEO STYLES */
.video-react .video-react-big-play-button {
  top: 200px;
  left: 310px;
}

.ant-select-multiple .ant-select-selection-item-remove {
  border-radius: 50%;
  padding: 1px 3px 0 3px;
  height: 70%;
  font-size: 9px;
  margin-top: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #16054e !important;
  color: white !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: white;
  border-radius: 16px;
}

.ant-pagination-item-active {
  border-color: #8d6cfa;
}

.ant-pagination-item-active a {
  color: #8d6cfa;
}
