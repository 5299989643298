.form {
  background: #ffffff;
  padding: 20px 40px;
  margin: 20px 0;
}
.updateButton {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  button {
    width: max-content;
    font-size: 16px;
    height: 40px;
    padding: 0 10px;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
  }
}
.audience {
  margin-right: 50px;
  span {
    margin-right: 10px;
    color: #16054e;
    font-weight: 500;
    font-size: 16px;
  }
}
.top-header-text {
  color: #16054e;
  font-size: 26px;
  margin: 30px 0;
  font-weight: 600;
}
.header-text {
  color: #16054e;
  font-size: 20px;
  margin: 30px 0;
  font-weight: 600;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.invest-form {
  margin-top: 40px;
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;
}

.field-width {
  width: 730px;
  margin-bottom: 10px;
}

.audience-grid {
  display: flex;
  flex-direction: row;
}
.date {
  margin-right: 50px;
  width: 320px;
}

.schedule-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    width: 20px;
  }
}
.option-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}
.name-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.littleLoading {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.accredited {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 85% !important;
  margin-top: 24px;
}

.field-width {
  width: 220px;
  margin-right: 40px;
  margin-top: 30px;
}
.profile-field-width {
  width: 350px;
  margin-right: 40px;
  margin-top: 30px;
}
.inputField {
  width: 730px;
  margin-bottom: 20px;
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  width: 99%;
  display: flex;
  height: 64px;
  margin-top: 20px;

  > button {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    background: none;
    padding: 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.quill-container {
  margin: 30px 0;
}
.quill {
  width: 730px;
  min-height: 100px !important;
}
.whatsappPreview {
  max-width: 730px;
  margin-top: 12px;

  span {
    color: #8d6cfa;
  }
}

.hiddenField {
  display: none;
}

.deleteTranslation {
  display: flex;
  gap: 10px;
  margin: 0;
  p {
    margin: 0;
    background-color: transparent;
  }
  p:hover {
    opacity: 0.5;
    border-radius: 50%;
  }
}
