.container-wrapper {
  padding: 20px;
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 32px;
    font-weight: 500;
  }
  .left-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  width: auto;
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  transition: 0.3s;
  font-weight: 500;
}
.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availability-toggler {
  background: #ffffff;
  padding: 4px;
  padding: 0 30px;
  width: 100%;
  display: flex;
  height: 50px;

  span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 0 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 3px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.startup-table {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 20px;
  .startup-header {
    display: flex;
    margin-bottom: 30px;
    padding: 10px 0;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
  }
}

.header-button {
  width: fit-content !important;
  padding: 5px 15px;
  height: 40px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
}

.startup-counts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  flex-direction: row;
}

.filter {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d2d6;
  margin-left: 20px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.table-height {
  min-height: 500px;
}
