.container {
  margin-top: 40px;
  padding: 24px;
  background: #fff;
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    > h2 {
      font-weight: 500;
      font-size: 24px;
      color: #16054e;
    }

    .actions {
      display: flex;
      align-items: center;

      .search {
        height: 40px;

        > input {
          height: 100%;
        }
      }

      .dropdown-btn {
        margin-left: 24px;
        background-color: #fff;
        border: 1px solid #ded7f6;
        border-radius: 32px;
        width: 134px;
        height: 40px;
        color: #16054e;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }

  .record-label {
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 16px;
    }

    > span {
      color: #16054e;
      font-size: 14px;
    }
  }

  :global {
    .ant-table-cell {
      color: #16054e;
      font-size: 14px;

      &:first-of-type {
        width: 300px;
      }

      &:nth-of-type(2) {
        width: 200px;
      }

      &:nth-of-type(3) {
        width: 250px;
      }

      &:nth-of-type(4) {
        width: 200px;
      }
    }
  }

  .button {
    width: 99px;
    height: 32px;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}

.topic-button {
  font-size: 14px;
  font-weight: 550;
  width: auto;
  margin-left: 10px;
  height: 40px;
  padding: 0 10px;
}
