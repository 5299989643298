.backdrop {
  background: rgba(6, 1, 37, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  left: 0px;
  top: 0px;
  backdrop-filter: blur(5px);
  animation: fadein 0.2s;
  z-index: 2301;
  @keyframes fadein {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
}

.hide {
  display: none;
}
.show {
  display: block;
}

.close {
  position: absolute;
  right: 4%;
  top: 6%;
}
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40%;
  height: auto;
  padding: 3rem;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 1rem;
  z-index: 2302;
}

//medium screens
@media screen and (max-width: 992px) {
  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    height: auto;
    padding: 2rem;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 1rem;

    &.md {
      width: 80%;
    }
  }
  .hr-modal-content {
    position: fixed;

    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 1rem;
    z-index: 2302;
    .right-grid {
      padding: 1rem;
    }
    .hr-modal-left {
      border-radius: 1rem;
      display: none;
    }
  }
}

//small screens
@media screen and (max-width: 550px) {
  .modal-content {
    position: fixed;
    top: 50%;
    right: 50%;
    width: 95%;
    height: auto;
    padding: 2rem;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 1rem;

    &.md {
      max-height: 500px;
      overflow-y: auto;
      width: 95%;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .hr-modal-content {
    position: fixed;

    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 1rem;
    z-index: 2302;
    .right-grid {
      padding: 1rem;
    }
    .hr-modal-left {
      border-radius: 1rem;
      display: none;
    }
  }
}

.hr-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 1rem;
  z-index: 2302;
  .right-grid {
    padding: 1rem;
  }
  .hr-modal-left {
    border-radius: 1rem;
  }
}

.sm {
  width: 30%;
}

.ant-modal-mask {
  background-color: red;
}
