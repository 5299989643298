.container {
  width: 100%;
  font-size: 1rem;
  height: 90vh;
  background: #fff;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  text-align: center;
  padding-top: 4em;
}

.container h1 {
  font-weight: 700;
  font-size: 3rem;
  margin: 30px 0;
}

.text {
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 10px 40px;
}
.text span {
  width: 60%;
}

.button-container {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home:hover {
  background-color: #8d6cfa;
  color: white;
}

.home {
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  color: #8d6cfa;
  font-size: 20px;
  border: 1px solid #8d6cfa;
  transition: 0.4s;
  margin-right: 20px;
  font-weight: 600;
  border-radius: 0.7em;
}

.go-back:hover {
  background: none;
  color: #8d6cfa;
}

.not-found-image {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.go-back {
  width: 200px;
  cursor: pointer;
  height: 60px;
  background-color: #8d6cfa;
  color: white;
  font-size: 20px;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #8d6cfa;
  font-weight: 600;
  border-radius: 0.7em;
}
.go-back img {
  width: 25px;
  height: 20px;
  margin-right: 10px;
}
.home img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: 0.4s;
  margin-bottom: 3px;
  background: none;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 2em;
    height: 700px;
  }
  .not-found img {
    width: 90vw;
    height: 300px;
  }
  .not-found h1 {
    font-size: 27px;
    margin: 10px 0;
  }
  .text {
    font-size: 20px;
  }
  .go-back {
    margin-right: 10px;
  }
  .go-back,
  .home {
    width: 170px;
    height: 50px;
    font-size: 17px;
  }
}
