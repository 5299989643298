.quill-container {
  margin: 30px 0;
}
.quill {
  width: 730px;
  min-height: 100px !important;
}

.image-section {
  margin: 20px 0;
}
