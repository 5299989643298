.width {
  width: 380px;
}

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 70px;
  flex-wrap: wrap;

  align-items: center;
}
.switch {
  width: 20%;
}

.fee {
  width: 100%;
  h4 {
    margin-bottom: 20px;
  }
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h2 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}

.switch {
  display: flex;
  width: 40%;
  justify-content: space-between;
}
