.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.modal-button {
  width: 80%;
  height: 56px;
  font-weight: 600;
  margin-top: 40px;
  align-items: center;
  font-size: 14px;
}
.modal-container {
  h1 {
    font-size: 24px;
    font-weight: 600;
    color: #16054e;
    margin-bottom: 40px;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 20px;
  background: #ffffff;
  .title {
    font-weight: 500;
    font-size: 32px;
    color: #16054e;
    margin-bottom: 40px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    border-radius: 10px;

    margin-right: 10px;
  }
}
.co-investors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}

.label-title {
  font-size: 18px;
  color: #16054e;
  margin: 20px 0;
}
.delete {
  margin-top: 35px;
  margin-right: 40px;
  img {
    cursor: pointer;
  }
}
.modal-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
