.card-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  .left-grid {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .right-grid {
    width: 70%;
    .form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }
}

.input-width {
  max-width: 300px !important;
}

.founder-ceo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.delete-button {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  float: right;
  cursor: pointer;
  div {
    display: flex;
    flex-direction: row;
  }
  span {
    color: red;
    font-size: 15px;
    font-weight: 500;
  }
  h6 {
    margin-right: 20px;
    color: #8d6cfa;
    font-size: 20px;
    font-weight: 600;
  }
}

.button {
  margin-right: 10px;
}
.founder-status {
  display: flex;
  flex-direction: column;
  max-width: 150px !important;
  justify-content: center;
}
.text-area-width {
  max-width: 500px !important;
}
.deleteMember {
  margin-left: 20px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 15px;
  }
}
