@import '../../globalstyles/variables';

.navLink {
  display: flex;
  align-items: center;
  color: $fade;
  font-size: 14px;
  padding: 15px 10px;

  &.active {
    background: $primary;
    color: $secondary;
    border-radius: 8px;
    font-weight: 700;
    margin: 10px 0;

    svg {
      path {
        fill: $secondary;
      }
    }
  }

  svg {
    margin-right: 10px;
    height: 22px;
    width: 22px;
  }
}

.plain {
  display: flex;
  align-items: center;
  color: $fade;
  font-size: 14px;
  padding: 20px 19px;

  &.active {
    color: $primary;
    border-radius: 8px;
    font-weight: 600;
    margin: 10px 0;

    svg {
      path {
        fill: $primary;
      }
    }
  }

  svg {
    margin-right: 19px;
    height: 22px;
    width: 22px;
  }
}
