.nav-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 40px;
  padding-top: 40px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
}
.creation-container {
  width: 90%;
  margin-top: 30px;
  margin-left: 40px;
  border-radius: 4px;
  padding: 30px;
  background-color: #ffffff;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #16054e;
    }
    span {
      font-size: 24px;
      font-weight: 500;
      color: #16054e;
    }
  }
  .content {
    margin-top: 30px;
    .about {
      margin-top: 60px;
      h1 {
        font-size: 24px;
        font-weight: 500;
        color: #16054e;
        margin-bottom: 20px;
      }
      .category {
        margin-top: 70px;
        width: 80%;
        h2 {
          margin-bottom: 15px;
        }
      }
      .input-details {
        width: 80%;
      }
    }
  }
}
.form-item {
  margin-bottom: 20px;
  width: 730px;
}

.numberValues {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.input-field {
  margin-bottom: 20px;
  width: 730px;
}

.input-width {
  max-width: 340px !important;
}

.form-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 92%;
}

.ql-editor {
  width: 85%;
  background: #f8f8fd;
  border-radius: 5px;
}

.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.button-modal {
  padding: 10px 25px;
  font-size: 15px;
  width: 210px;
  height: 50px;
  margin-top: 10px;
  margin-right: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  margin: 20px;
  h1 {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: bold;
  }
  span {
    font-size: 18px;
    text-align: center;
  }
  .buttons-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.input-number {
  width: 340px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
