.navigation {
  display: flex;
  align-items: center;
  flex-direction: row;
  img {
    margin-right: 30px;
    cursor: pointer;
  }
  span {
    font-size: 28px;
    font-weight: 500;
    color: #8b82a7;
    .h-style {
      color: #16054e;
    }
  }
}
.top-header-text {
  color: #16054e;
  font-size: 26px;
  margin: 30px 0;
  font-weight: 600;
}
.title {
  color: #16054e;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 16px;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.key-facts {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 30px 0;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  img {
    margin-left: 30px;
    height: 24px;
    width: 24px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}

.category {
  margin-top: 20px;
}

.date-format {
  justify-content: space-between;
  width: 75%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  .publish {
    margin-right: 10px;
  }
}

.content {
  background-color: #ffffff;
  padding: 20px;
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.content {
  padding: 40px;
  margin-top: 30px;
  background-color: #ffffff;
  .image-wrapper {
    h1 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    img {
      width: 796px;
      height: 320px;
      margin-bottom: 20px;
    }
  }
}
.form {
  margin-top: 40px;
  background-color: #fff;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.field {
  margin-top: 20px;
}

.button {
  width: fit-content !important;
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

.related-topics {
  margin-top: 50px;
  h1 {
    font-size: 24px;
  }
}
.duration {
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: space-between;
}

.takeaway-container {
  margin-top: 50px;
  h1 {
    font-size: 24px;
  }
  .take-away {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-left: 5px;
      cursor: pointer;
      margin-bottom: 5px;
    }
  }
}

.takeaway-list {
  width: 80%;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 4px;
  height: 30px;
  margin-bottom: 10px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.addSection {
  background-color: transparent;
  border: none;
}
.modal-title {
  font-size: 24px;
  color: #16054e;
  font-weight: 600;
}

.quill {
  width: 730px;
  min-height: 100px !important;
  margin-top: 8px;
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.input-file {
  visibility: hidden;
}
.video-upload {
  margin-bottom: 30px;
}
.upload {
  max-width: 730px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  span {
    font-size: 20px;
    font-weight: 500;
    color: #16054e;
  }
}
.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-button {
  margin-top: 20px;
  color: red;
  background: none;
  width: 170px;
  height: 40px;
  font-weight: 500;
  border: 1px solid red;
  transition: ease-in-out 0.4s;
  margin-left: 10px;
  font-size: 14px;
}

.delete-button:hover {
  color: white;
  background-color: red;
  border: none;
}
.note {
  padding-left: 10px;
  color: #16054e;
}
