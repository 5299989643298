.root {
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 94%;
  display: flex;
  height: 64px;
  margin-top: 30px;
  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.content {
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  min-height: 500px;
}

.topButton {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
}

.button {
  padding: 0 10px;
  width: max-content;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  div {
    text-align: center;
    h1 {
    }
  }
  h1 {
    font-size: 22px;
  }
}
.fieldWidth {
  width: 300px;
}
