.container {
  background: #ffffff;
  padding: 5px 0 30px 30px;
  h1 {
    color: #16054e;
    font-size: 24px;
    margin-top: 30px;
    font-weight: 500;
  }
}
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload {
  max-width: 700px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  span {
    font-size: 16px;
    font-weight: 400;
    color: #16054e;
  }
}
.input-file {
  visibility: hidden;
}
.image {
  margin: 20px 0;
  .title {
    color: #16054e;
    font-size: 24px;
    margin-top: 40px;
    font-weight: 500;
  }
}

.submit {
  margin: 60px 0;
}

.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
  }
}
.pitch-body {
  margin: 40px;
  h1 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
    color: #16054e;
  }
}
.button-wrapper {
  margin-top: 30px;
  button {
    font-size: 14px;
    margin-right: 20px;
    transition: 0.5s;
  }
}
.pdf-height {
  max-height: 100vh;
  overflow-y: scroll;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.field-width {
  width: 700px;
  margin-bottom: 25px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}
