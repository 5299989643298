.card {
  height: 131px;
  flex: 1;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
  p {
    color: #8c94a6;
  }
  h1 {
    font-size: 25px;
  }
}

.topCards {
  display: flex;
  margin-top: 20px;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tableHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  h1 {
    font-size: 20px;
  }
  button {
    width: 120px;
    height: 40px;
    transition: 0.3s ease-in-out;
    font-size: 14px;
  }
}

.imageProfile {
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
}
