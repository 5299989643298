.form-item {
  label {
    display: block;
    margin-top: 10px;
  }
}

.form-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.header {
  font-size: 24px;
  color: #16054e;
  margin-top: 40px;
}
.input-width {
  max-width: 340px !important;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
