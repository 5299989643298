.container {
	margin-top: 16px;
	border-radius: 8px;
	background-color: #fff;
	padding: 36px;
  
	.heading-text {
	  color: #16054e;
	  font-size: 24px;
	  font-weight: 500;
	}
  
	.report-tags {
	  margin: 40px 0px;
	  display: flex;
	  max-width: 796px;
	  flex-wrap: wrap;
  
	  .tag-icon {
		width: 56px;
		height: 56px;
		position: relative;
		margin-right: 16px;
  
		> img {
		  width: 100%;
		  height: 100%;
		  border-radius: 50%;
		}
  
		> button {
		  width: fit-content;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  background-color: transparent;
		  padding: 0px;
		  position: absolute;
		  z-index: 2;
		  top: 0;
		  right: 0;
  
		  &:hover {
			opacity: 0.8;
		  }
		}
	  }
  
	  .add-tag-button {
		width: 56px;
		height: 56px;
		border-radius: 50%;
		background-color: #f8f8fd;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #8d6cfa;
		font-size: 20px;
  
		&:hover {
		  opacity: 0.8;
		}
	  }
	}
  
	.form-style {
	  max-width: 796px;
	}
  
	.add-button {
	  background-color: transparent;
	  margin-top: 26px;
	  display: flex;
	  align-items: center;
  
	  > span {
		font-size: 14px;
		color: #8d6cfa;
		font-weight: 600;
		margin-left: 10px;
	  }
	}
  
	.submit-button {
	  font-weight: 600;
	  width: 145px;
	  height: 40px;
	  margin-top: 42px;
	}
  }
  
  .all-data {
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
	  width: 20px;
	  height: 20px;
	  margin-right: 10px;
	}
  }
  .loading {
	display: flex;
	flex-direction: column;
	margin-top: 259px;
	justify-content: center;
	align-items: center;
  }
  
  .cover-image {
	margin-bottom: 30px;
  }
  