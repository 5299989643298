.container {
  width: 300px;
  height: 370px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 16px;
}
.image {
  img {
    width: 300px;
    height: 241px;
    border-radius: 16px;
  }
  h1 {
    color: #16054e;
    font-size: 22px;
    padding-left: 15px;
    margin: 10px 0;
  }
}
.author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  margin-top: 30px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}
.name,
.source {
  h4 {
    color: #16054e;
    font-size: 14px;
  }
  span {
    color: #8b82a7;
    font-size: 10px;
  }
}
.source {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
  flex-direction: row;
  h4 {
    margin-right: 10px;
  }
}
.title {
  padding-left: 20px;
  margin-top: 20px;
  h1 {
    color: #16054e;
    font-size: 22px;
  }
}

.saved-header {
  font-size: 22px;
  margin-top: 30px;
  padding: 0 15px;
}
