.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    margin-right: 20px;
  }
}
.not-pending {
  background-color: #f6f6f9;
  color: #5c5083;
  padding: 10px 15px;
  border-radius: 16px;
  font-size: 10px;
}
.row-width {
  max-width: 200px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.pending {
  background: #fef4e5;
  border-radius: 16px;
  padding: 10px 15px;
  border-radius: 16px;
  font-size: 10px;
  color: #f5a623;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
