.navigation {
    display: flex;
    align-items: center;
    flex-direction: row;
    img {
      margin-right: 30px;
      cursor: pointer;
    }
    span {
      font-size: 28px;
      font-weight: 500;
      color: #8b82a7;
      .h-style {
        color: #16054e;
      }
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    h1 {
      color: #8b82a7;
    }
    span {
      color: #16054e;
    }
  }
  
  .content {
    padding: 40px;
    margin-top: 30px;
    .image-wrapper {
        border-radius: 16px;
        margin-bottom: 16px;
        .faq-background{
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }
    .content-wrapper{
        .faq-question{
            color: #16054E;
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 16px;
        }
        .author{
            color: #8B82A7;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 24px;
        }
        .faq-content{
            h1, h2, h3, h4, h5, h6{
                color: #16054e;
                margin-bottom: 16px;
            }
            margin: 56px auto;
            color: #8B82A7;
            font-size: 18px;
        }
        .feedback-card{
            border: none;
        }
    }
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    margin-top: 259px;
    justify-content: center;
    align-items: center;
  }
  