.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: 20px;
    margin-right: 20px;
    height: 20px;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.7;
  }
}

.content {
  background-color: #fff;
  padding: 20px;
  h2 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.field {
  max-width: 600px;
  margin-bottom: 20px;
}

.deposits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 60%;
}

.input {
  width: 120px;
}
