.image {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 20px;
  }
}
.field-width {
  max-width: 730px;
}
