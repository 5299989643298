.ac-container {
  max-width: 340px;
  position: relative;
  min-height: 470px;
  margin-right: 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 32px;
}

.ac-container-live {
  height: 490px;
  max-width: 340px;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  position: relative;
  margin-right: 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 32px;
}
.campaignMode {
  position: absolute;
  z-index: 1000;
  .test {
    background-color: #f7b344;
    padding: 10px 25px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    color: white;
  }
  .live {
    background-color: #16054e;
    padding: 10px 25px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    color: white;
  }
  .visible {
    background-color: #6fd45d;
    padding: 10px 25px;
    border-radius: 25px;
    font-size: 14px;
    margin-left: 10px;
    font-weight: 700;
    color: white;
  }
  .hidden {
    background-color: #d33b3b;
    margin-left: 10px;

    padding: 10px 25px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    color: white;
  }
}
.ac-container:hover,
.ac-container-live:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.ac-image {
  padding: 10px;
  position: relative;
  overflow: hidden;
  .image {
    max-width: 320px;

    height: 200px;
    border-radius: 8px;
    transition: all 0.2s ease-in;
  }
  .image:hover {
    transform: scale(1.1);
  }
}
.ac-title {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
}
.ac-logo {
  position: absolute;
  z-index: 99;
  width: 56px;
  bottom: 2px;
  left: 24px;
  height: 56px;
  border-radius: 100%;
}
.ac-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.ac-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  justify-content: space-between;
  margin-top: 25px;
  h1 {
    color: #16054e;
    font-size: 18px;
    font-weight: 500;
  }
}
.ac-series {
  .ac-f-series {
    font-size: 10px;
    font-weight: 600;
    color: #8b82a7;
    background: #eeedf1;
    margin-right: 8px;
    border-radius: 32px;
    padding: 5px 12px;
  }
  .t-ipo,
  .t-venture {
    border-radius: 32px;
    padding: 5px 12px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 32px;
  }
  .t-ipo {
    color: #f7b344;
    background: #fef4e5;
  }
  .t-venture {
    color: white;
    background: #16054e;
  }
}

.description {
  color: #8b82a7;
  font-size: 14px;
  font-weight: 400;
}

.thumbnails {
  margin: 12px 0;
}
.ac-body {
  padding: 10px;
}

.capital {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  h1 {
    font-size: 24px;
    color: #16054e;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
    color: #8b82a7;
  }
}
.bottom-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 200px;
  min-height: 190px;
}

.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 18px 10px;
  border-bottom: 1px solid #ded7f6;
  img {
    margin-right: 10px;
  }
}
.modal-content {
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
}

.button {
  margin-right: 20px;
  font-size: 18px;
  transition: 1s;
  font-weight: 600;
  width: 150px;
  height: 45px;
}
