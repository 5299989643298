.container {
  padding: 10px 10px 63px;

  .header {
    display: flex;
    align-items: center;

    > button {
      background-color: transparent;
      width: fit-content;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > h1 {
      font-size: 32px;
      color: #16054e;
      font-weight: 500;
      margin-left: 20px;
    }
  }

  .inner-container {
    background-color: #fff;
    border-radius: 4px;
    padding: 40px;
    margin-top: 40px;

    .section {
      margin-bottom: 56px;

      &:nth-of-type(3) {
        margin-bottom: 24px;
      }

      .sub-heading {
        color: #16054e;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 24px;
      }

      .small-heading {
        font-size: 14px;
        color: #16054e;
        font-weight: 400;
        margin-bottom: 8px;
      }

      :global {
        .ant-select {
          width: 100%;
        }

        .ant-select-selector {
          min-height: auto;
          height: 48px;
        }

        .ant-select-selection-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 0px;
        }
      }
    }
    .topic-width {
      width: 720px;
    }
    .input-number {
      width: 100%;
      border: 1px solid #ded7f6;
      padding: 6px;
      border-radius: 8px;
      background-color: #f8f8fd;
    }

    .button {
      width: 241px;
      height: 48px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.topic-lists {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}
.delete {
  margin-top: 35px;
  margin-right: 40px;
  img {
    cursor: pointer;
  }
}
.opportunity {
  margin-top: 20px;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    border-radius: 10px;

    margin-right: 10px;
  }
}
