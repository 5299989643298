.container-wrapper {
  padding: 20px;
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 32px;
    font-weight: 500;
  }
  .left-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availability-toggler {
  background: #ffffff;
  padding: 4px;
  padding: 0 30px;
  width: 100%;
  display: flex;
  height: 50px;

  span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 0 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 3px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.startup-table {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 20px;
  .startup-header {
    display: flex;
    margin-bottom: 30px;
    padding: 10px 0;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
  }
}

.header-button {
  width: fit-content !important;
  padding: 5px 15px;
  height: 40px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
}

.startup-counts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  flex-direction: row;
}

.filter {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d2d6;
  margin-left: 20px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.table-height {
  min-height: 500px;

  .expired {
    color: #f5a623;
    background: #fef4e5;
    padding: 4px 8px;
    border-radius: 16px;
  }
  .active {
    color: #5c5083;
    background: #f6f6f9;
    padding: 4px 8px;
    border-radius: 16px;
  }
  .deactivate-btn {
    width: 150px;
    height: 32px;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input-number {
  width: 100%;
  border: 1px solid #ded7f6;
  padding: 6px;
  border-radius: 8px;
  background-color: #f8f8fd;
}
.modal-container {
  height: 700px;
  overflow-y: scroll;
  padding: 20px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}
.modal-header {
  margin-bottom: 20px;
}

.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}
