.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.form {
  background: white;
  padding: 20px;
  h1 {
    margin-bottom: 20px;
  }
  .field {
    width: 700px;
    margin-bottom: 15px;
  }
  .fieldFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 67%;
    align-items: center;
    justify-content: space-between;
  }
  .smallWidth {
    width: 310px;
    margin-bottom: 15px;
  }
  h2 {
    margin: 40px 0 20px;
    font-size: 16px;
  }
  .number {
    width: 300px;
    margin-bottom: 15px;
  }
}

.header {
  display: flex;
  gap: 10px;
}

.input-number {
  width: 310px;
  padding: 4px;
  border-radius: 8px;
  height: 45px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
.topic-name {
  display: flex;
  align-items: center;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 24px;
  }

  h4 {
    color: #16054e;
    font-size: 16px;
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.al-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  .delete-icon {
    margin-left: 20px;
    height: 25px;
    width: 25px;
  }
}

.durationField {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
  margin-bottom: 20px;
  width: max-content;
  border-radius: 16px;
}

.durationMultiplier {
  .multiplier {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
  }
  .multiplierNumber {
    width: 120px;
    padding: 4px;
    border-radius: 8px;
    height: 45px;
    background-color: #f8f8fd;
    border: 1px solid #ded7f6;
    &:hover,
    &:focus {
      border-color: #8d6cfa;
    }
  }
}
