.content {
  background: #ffffff;
  padding: 20px;
  margin: 20px 0;
}
.details {
  display: flex;
  flex-direction: column;
  margin: 20px;
  flex-wrap: wrap;
}
.title {
  color: #16054e;
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.features {
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: center;
  margin: 0 30px;
}
.item {
  border: 1px solid #ece7e7;
  width: 70%;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-top: 20px;
}
.add-item {
  display: flex;
  flex-direction: row;
  background: none;
  margin: 30px 0;
  align-items: center;
  cursor: pointer;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}
.delete-feature {
  float: right;
  img {
    width: 30px;
    height: 30px;
    margin-left: 60px;
    cursor: pointer;
  }
}
.close-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 3px 15px;
}
.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.field-width {
  width: 720px;
  margin-bottom: 20px;
}

.price-amount {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
  padding: 20px 10px;
  width: 33%;
  border: 1px solid #ece7e7;
  h1 {
    margin-right: 10px;
    color: #16054e;
    font-size: 16px;
    font-weight: 500;
  }
  span {
    font-size: 16px;
  }
}
