.root {
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    color: #b0b0b0;
    font-size: 15px;
    text-align: center;
  }
}
