.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  img {
    width: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.form {
  background: white;
  padding: 20px;
}

.breakdown {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 10px;

  width: 100%;
}

h2 {
  color: #16054e;
  font-size: 14px;
  font-style: normal;
  margin-top: 20px;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  margin-bottom: 10px;
  text-transform: uppercase;
}

.width {
  width: 400px;
  background: #f9f9f9;
  height: 40px;
  border-radius: 5px;
}
.fieldWidth {
  margin-bottom: 20px;
}
.fees {
  margin-bottom: 30px;
}

.display {
  display: flex;
  gap: 30px;
  margin: 0 20px;
}

.topNav {
  display: flex;
  justify-content: end;
  margin: 20px 0;
  align-items: flex-end;
  h1 {
    flex: 0.5;
    align-self: center;
    text-align: center;
  }
}

.seeded-documents {
  background-color: #fff;
  margin-top: 20px;
  padding: 15px;
}

.copy-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 120px;
  cursor: pointer;
  color: rgb(113, 113, 179);
  .tooltip {
    visibility: hidden;
    background-color: #8b82a7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 80px;
    cursor: pointer;
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.copy-label:hover .tooltip {
  visibility: visible;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
}

.filter {
  margin-left: 20px;
  display: flex;
  padding: 8px 16px !important;
  height: 50px;
  cursor: pointer;
  align-items: flex-start;
  gap: 8px;
  flex-direction: row;
  transition: 0.5s ease-in-out;
  border: 1px solid #ded7f6;
  border-radius: 32px;
  align-items: center;
  h2 {
    color: #16054e;
    font-size: 14px;
    font-style: normal;
    margin: 0;
    font-weight: 500;
    line-height: 24px;
  }
}
.filter:hover {
  opacity: 0.5;
}

.filterRoot {
  width: 100%;
}

.filterRootHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  width: 95%;
  h1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
  p {
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #b0b0b0;
    padding: 8px 13px;
    transition: 0.5s ease-in-out;
    margin-bottom: 10px;
  }
  p:hover {
    opacity: 0.5;
  }
}

.price {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  img:hover {
    cursor: pointer;
  }
}

.nameRowWidth {
  width: 250px;
}

.rowWidth {
  width: 150px;
}
.pageNumber {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
