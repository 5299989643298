.container {
  background: #ffffff;
  margin-top: 20px;
  padding: 30px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.search-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  width: auto;
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  transition: 0.3s;
  margin-left: 10px;
  font-weight: 500;
}

.not-found {
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  justify-content: center;
  align-items: center;
  h1 {
    margin: 10px 0;
    font-size: 30px;
  }
  span {
    color: #8b82a7;
    font-size: 18px;
  }
}

.paginationBttns a {
  border: 1px solid white;
  color: #333;
  font-weight: bold;
}

.paginationBttns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  list-style: none;
  margin-top: 3rem;
}

.paginationBttns a {
  padding: 15px 20px;
  margin: 8px;
  border-radius: 100px;
  border: 1px solid #8d6cfa;
  color: #8d6cfa;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.paginationBttns a:hover {
  color: #8d6cfa;
  border: 1px solid #8b82a7;
  // background-color: #8b;
}

.previousBttn a {
  color: #8b82a7;
}
.paginationActive a {
  color: #ffffff;
  border-radius: 100px;
  border: #8d6cfa;
  background-color: #8d6cfa;
}

.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.search-field {
  border: 2px solid #d3d0de;
  border-radius: 100px;
  padding: 9px;
  width: 270px;
  height: 45px;
  input {
    border: none;
  }
  input:focus {
    outline: none;
  }
  span {
    color: #8d6cfa;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
  }
  span:hover {
    opacity: 0.8;
  }
}
