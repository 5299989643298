.container {
  // padding: 5px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    img {
      cursor: pointer;
      margin-right: 15px;
    }
    span {
      font-size: 24px;
      font-weight: 500;
      color: #8b82a7;
    }
    .o-text {
      color: #16054e;
    }
  }
  .body {
    background-color: #ffffff;
    margin: 10px;
    padding: 20px;
    border-radius: 16px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
      padding: 5px 20px;
      height: 70vh;
      overflow-y: scroll;
      flex-direction: row;
      width: 100%;
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
