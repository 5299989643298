.startup-table {
  width: 90%;
  margin-top: 20px;
  min-height: 600px;
  margin-left: 20px;
}
.record-name {
  display: flex;
  flex-direction: row;
  min-width: 100px !important;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 120px;
  padding: 0 6px;
  .tooltip {
    visibility: hidden;
    background-color: #8b82a7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 80px;
    cursor: pointer;
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.record-label:hover .tooltip {
  visibility: visible;
}

.modal-container {
  h1 {
    margin-bottom: 20px;
  }
}

.table-container {
  background: #ffffff;
  width: 100%;
  margin-top: 10px;
  padding-top: 20px;
  .header {
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 24px;
    }
  }
}
.button-wrapper {
  margin-right: 20px;
}
.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 250px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}
.no-updates {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  h1 {
    color: black;
    font-size: 20px;
    font-weight: 600;
  }
  img {
    width: 327px;
    height: 230px;
  }
  span {
    text-align: center;
    margin: 20px 0;
    width: 50%;
  }
}

.add-single {
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
  align-items: center;
  .image-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    div {
      .header {
        font-size: 18px;
        margin-bottom: 2px;
      }
      span {
        color: #8b82a7;
      }
    }

    img {
      width: 50px;
      margin-right: 20px;
      height: 50px;
    }
  }
}

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  span {
    margin-bottom: 10px;
    text-align: center;
    width: 80%;
    color: #8b82a7;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.modal-loading {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.startup-max-height {
  max-height: 600px;
  min-height: 600px;
  overflow-y: scroll;
}
.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 18px 10px;
  border-bottom: 1px solid #ded7f6;
  img {
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 170px;
  justify-content: space-between;
  align-items: center;
  .action-icon {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    color: #8b82a7;
    img {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .action-icon:hover {
    opacity: 0.8;
  }
}
.user-table {
  overflow-x: scroll;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 94%;
  display: flex;
  height: 64px;
  > span {
    font-size: 15px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
    :hover {
      color: #8d6cfa;
    }
  }
  .active {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.filterSection {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row-reverse;
  margin: 10px;
  .txButton {
    width: max-content;
    padding: 0 10px;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .filter {
    margin-left: 20px;
    display: flex;
    padding: 8px 16px !important;
    height: 50px;
    cursor: pointer;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;
    transition: 0.5s ease-in-out;
    border: 1px solid #ded7f6;
    border-radius: 32px;
    align-items: center;
    h2 {
      color: #16054e;
      font-size: 14px;
      font-style: normal;
      margin: 0;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
  .filter:hover {
    opacity: 0.5;
  }
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
}
