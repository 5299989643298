.otp-text-input-container {
  width: 60px;
  margin-bottom: 10px;
  position: relative;
  input {
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid #ded7f6;
    border-radius: 6px;
    outline: none;
    color: #8b82a7;
    font-size: 30px;
    text-align: center;
  }
  .active {
    color: #8d6cfa;
  }

  input:focus {
    border: 1px solid #8d6cfa;
  }

  input:valid {
    border-color: #8d6cfa;
  }
}
