.nav-buttons {
  margin: 30px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.tableContainer {
  button {
    width: auto;
    padding: 8px 16px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
.record-label {
  display: flex;
  flex-direction: row;
  width: 150px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.bool {
  display: flex;
  align-items: start;
  gap: 20px;
  button {
    padding: 0 !important;
  }
}

.record-name {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.modal {
  height: auto;
  text-align: center;
  .type {
    color: red;
    font-weight: bold;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  button {
    width: auto;
    padding: 8px 16px;
  }

  span {
    text-align: center;
    font-size: 16px;
  }
}
.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
  button {
    width: 150px;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}
.filterSection {
  display: flex;
  margin-left: 200px;
  position: relative;
  align-items: center;
  button {
    width: auto;
    padding: 8px 16px;
    margin-left: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .filter {
    margin-left: 20px;
    display: flex;
    padding: 8px 16px !important;
    height: 50px;
    cursor: pointer;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;
    transition: 0.5s ease-in-out;
    border: 1px solid #ded7f6;
    border-radius: 32px;
    align-items: center;
    h2 {
      color: #16054e;
      font-size: 14px;
      font-style: normal;
      margin: 0;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
  .filter:hover {
    opacity: 0.5;
  }
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 500px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
}

.filters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.companyHeader {
  display: flex;
  width: max-content;
  align-items: center;
  flex-direction: row;
  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-right: 4px;
    text-transform: uppercase;
  }
}

.top {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;
  }
  h1 {
    font-size: 24px;
  }
  margin-bottom: 30px;
}

.content {
  background: #fff;
  padding: 20px 10px;
}

.date {
  width: 100%;
}

.price {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.smallWidth {
  width: 50%;
}

.collectionWidth {
  width: 250px;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}

.refresh {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  transition: 0.5s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  h1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 100px;
  }
  p {
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #b0b0b0;
    padding: 8px 13px;
    transition: 0.5s ease-in-out;
    margin-bottom: 10px;
  }
  p:hover {
    opacity: 0.5;
  }
}

.historyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  h1 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    align-self: center;
    margin-bottom: 40px;
  }
}

.matchOrder {
  color: blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.noNote {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.createNote {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .width {
    width: 600px;
    margin-bottom: 20px;
  }
}

.field {
  margin-bottom: 20px;
}

.topCreate {
  float: right;
  margin-bottom: 20px;
}

.createTop {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  img {
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    :hover {
      scale: 1;
    }
  }
  h1 {
    flex: 1;
    text-align: center;
  }
}

.highlightRow {
  background-color: #f0f0f0;
}
.settleDate {
  margin: 40px;
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: max-content;
  margin-bottom: 20px;
  margin-left: 25px;
  padding: 5px;
  border-radius: 8px;
  h5 {
    margin-left: 5px;
  }
}
.emailField {
  display: flex;
  gap: 20px;
  align-items: center;
  .emailWidth {
    width: 400px;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.emails {
  max-height: 400px;
  overflow: auto;
}

.uploadSec {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.upload {
  max-width: 300px;
  min-height: 70px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #16054e;
  }
}

.uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-file {
  visibility: hidden;
}

.selectedRow {
  width: 100%;
  align-self: flex-start;
  display: flex;
  margin: 20px 0;
}

.orderSort {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
  padding: 10px;
}

.updateAllFields {
  width: 150px;
  float: right;
}

.allUpdate {
  display: flex;

  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-top: 20px;
  }
}

.bulkTop {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.allField {
  display: flex;
  align-items: center;
  gap: 4px;
  .allButton {
    align-items: center;
    height: 42px;
    margin-top: 8px;
    font-size: 10px;
    border-radius: 8px;
  }
}

.search {
  width: 300px;
  display: flex;
  margin-bottom: 20px;
}
