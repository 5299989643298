.field {
  width: 700px;
  margin-bottom: 20px;
}
.top {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.form {
  padding: 30px;
  background: #fff;
}

.image {
  margin-bottom: 10px;
}

.tabs {
  max-width: 750px;
}
