.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  align-items: center;
  width: 90%;
  h1 {
    font-size: 20px;
    flex: 1;
    text-transform: uppercase;
    font-weight: bold;
    align-self: center;
    text-align: center;
  }
  p {
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #b0b0b0;
    padding: 8px 13px;
    transition: 0.5s ease-in-out;
    margin-bottom: 10px;
  }
  p:hover {
    opacity: 0.5;
  }
}
.fieldWidth {
  width: 230px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.allData {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.deposit {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  > :nth-child(1),
  :nth-child(2),
  :nth-child(3) {
    flex: 1;
  }
}
