.container {
	padding: 10px;

	.header {
		display: flex;
		align-items: center;

		> button {
			background-color: transparent;
			width: fit-content;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		> h1 {
			color: #8b82a7;
			font-size: 24px;
			font-weight: 400;
			margin-left: 20px;

			span {
				color: #16054e;
				font-weight: 500;
				text-transform: capitalize;
			}
		}
	}

	.section {
		margin: 20px 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tab-toggler {
		background: #ffffff;
		padding: 4px;
		width: 100%;
		display: flex;
		padding: 0 30px;
		height: 64px;
	}

	.nav-link {
		font-size: 14px;
		line-height: 24px;
		margin-right: 20px;
		display: flex;
		padding: 4px 4px;
		transition: 0.3s;
		justify-content: center;
		border-bottom: 4px solid #fff;
		font-weight: 500;
		align-items: center;
		cursor: pointer;
		color: #8b82a7;
	}

	.nav-link:hover {
		color: #8d6cfa;
	}

	.active-toggle {
		color: #8d6cfa;
		border-color: #8d6cfa;
	}
}
