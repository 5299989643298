.tab-container {
  display: flex;
  gap: 10px;
}

.tab-container {
  background: #ffffff;
  padding: 4px 25px;
  display: flex;
  margin-bottom: 20px;
  height: 64px;
  margin-top: 30px;
  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.totalReferral {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
