.container,
.disabledContainer {
  height: 300px;
  width: 230px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  justify-content: center;
  margin-bottom: 30px;
  flex-direction: column;
  background: #ffffff;
  cursor: pointer;
  .menu {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px 0;
    float: right;
    width: 100%;
  }
  .image-style {
    img {
      width: 120px;
      height: 120px;
      border-radius: 80px;
    }
  }
  h1 {
    font-size: 19px;
    color: #16054e;
    font-weight: 600;
    margin-top: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  h2,
  span {
    font-size: 16px;
    color: #8b82a7;
    font-weight: 400;
  }
  span {
    margin-top: 8px;
  }
}
.menu-dropdown {
  cursor: pointer;
}
.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
}

.disabledContainer:hover {
  cursor: not-allowed;
}

.container:hover {
  background: #e1e0e6cf;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
