.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  .top {
    margin-bottom: 30px;
  }
}

.fieldWidth {
  width: 800px;
  margin-bottom: 20px;
}

.suggestedAmounts {
  display: flex;
  gap: 40px;
  .numberField {
    height: 40px;
    width: 130px;
    border-radius: 8px;
    background-color: #f8f8fd;
  }
}

.deleteButton {
  margin-left: 10px;
  color: red;
  border-color: red;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  &:hover {
    background: red;
    color: #fff;
  }
}

.quill-container {
  margin: 30px 0;
}

.quill {
  width: 800px;
  height: 200px !important;
  // height: 100px;

  margin-top: 8px;
}

.image-uploaded {
  width: 240px;
  height: auto;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.upload-container {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}

.imageContainer {
  margin: 20px;
}

.delete-button {
  margin: 10px;
}

.affiliateConfig {
  background: #fff;
  padding: 20px;
  h1 {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin: 20px;
  }
}

.createBtn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0;
  button {
    width: max-content;
    padding: 0 10px;
    transition: 0.3s ease-in-out;
  }
}

.default-config-row {
  background: #e5e5e5;
  font-weight: bold;
  border-radius: 8px;
}
