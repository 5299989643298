.container {
  width: 256px;
  height: 112px;
  background: #f5f6fa;
  border-radius: 8px;
  transition: 0.3s;
  padding: 10px;
  border: 10px solid #ffffff;
  cursor: pointer;
}

.active-container {
  width: 256px;
  height: 112px;
  border-radius: 8px;
  transition: 0.3s;
  padding: 10px;
  cursor: pointer;
  background: #8d6cfa;
  border: 10px solid #a48afa;
  h1,
  span {
    color: #ffffff;
  }
}
