.title {
  margin: 40px 0;
  h1 {
    color: #16054e;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  span {
    color: #8b82a7;
    font-size: 14px;
    font-weight: 400;
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.upload {
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 48px;
  align-items: center;
  padding: 0;
  border-radius: 8px;
  padding-left: 17px;
  border: 1px solid #ded7f6;
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
    margin-right: 18px;
  }
  span {
    color: #8b82a7;
    font-size: 14px;
    font-weight: 400;
  }
}

.uploadFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.file-upload {
  display: none;
}
.document {
  margin-bottom: 40px;
  display: flex;
  width: 350px;
  flex-direction: row;
}
.container {
  background: #ffffff;
  padding: 10px 0 40px 20px;
}
.document-card {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;

  border-radius: 30px;
  margin: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.field {
  margin-bottom: 20px;
}
