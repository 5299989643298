.form {
  background: white;
  padding: 30px;
  margin: 20px 0;
}

.field {
  width: 700px;
  margin: 20px;
}
.switch {
  margin-left: 20px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 15px;
    width: 20px;
    cursor: pointer;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
