.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  img {
    width: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.container {
  background: #ffff;
  padding: 20px;
}

.field-width {
  width: 400px;
  margin-bottom: 40px;
  margin-left: 20px;
}
.price-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
