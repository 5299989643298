.container {
  max-width: 321px;
  min-width: 321px;
  min-height: 600px;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 8px;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 1px solid #e2e0e9;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 600;
  }
  .header-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
.amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  h1 {
    color: #16054e;
    font-size: 18px;
    font-weight: 600;
  }
  h2 {
    color: #8b82a7;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 600;
  }
}
.loader {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
  margin: 20px;
}
.price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}
.users {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    color: #8d6cfa;
    font-weight: 700;
    font-size: 14px;
  }
  span {
    color: #8b82a7;
    font-size: 14px;
    font-weight: 400;
  }
}
.plan {
  h1 {
    color: #16054e;
    font-size: 16px;
    margin: 20px 0;
    font-weight: 600;
  }
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 10.5px;
    }
    span {
      color: #8b82a7;
    }
  }
}
.bold-text {
  font-weight: 600;
  color: #16054e !important;
}
.modal-title {
  color: #16054e;
  font-size: 32px;
  margin: 20px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600;
}
.button {
  width: 170px;
  font-size: 16px;
  font-weight: 600;
  height: 45px;
  margin-right: 20px;
}
.modal-container {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.price-title {
  color: #16054e;
  font-size: 20px;
  margin: 10px;
  text-align: center;
  font-weight: 600;
}
.free-plan {
  display: flex;
  align-items: center;
  height: 100px;
  margin: 30px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  justify-content: center;
  h1 {
    font-size: 20px;
    color: #8b82a7;
    font-weight: 500;
  }
}
.price-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid #cfcbdc;
}
