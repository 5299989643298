.active {
  background-color: #52d785;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 16px;
  font-size: 12px;
}
.inactive {
  background: #f6f6f9;
  border-radius: 16px;
  padding: 8px 15px;
  border-radius: 16px;
  font-size: 12px;
  color: #f53f23;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 150px;
  justify-content: center;
  img {
    margin-left: 15px;
    cursor: pointer;
    width: 25px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  h1 {
    margin-bottom: 20px;
  }
}
