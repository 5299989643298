.root {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 60vh;
  background: #fff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    h1 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 30px;
      text-align: center;
    }
    p {
      color: #b0b0b0;
      margin-top: 10px;
      font-size: 20px;
    }
  }
  .otp {
    display: flex;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
