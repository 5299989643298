.add-faq {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}
.question-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-left: 20px;
    cursor: pointer;
  }
}
.container {
  background: #ffffff;
  padding: 30px;
}

.title {
  color: #16054e;
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 500;
}
.faq {
  margin-bottom: 40px;
  padding: 20px;
  width: 85%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
