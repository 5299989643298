@import "globalstyles/mixings";
@import "globalstyles/_variables.scss";
.text-input-container {
  width: 100%;
  margin-bottom: 4px;
  position: relative;
  .input {
    display: flex;
    align-items: center;
    padding: 17px;
    padding-left: 40px;
    border: 1px solid;
    border-color: #ded7f6;
    border-radius: 6px;
    margin-top: 3px;
    width: 100%;
    outline: none;
    color: #8b82a7;
    font-size: 14px;
  }

  .input:focus {
    border-color: $primary;
  }

  .icon {
    position: absolute;
    top: 43px;
    left: 16px;
    font-size: 0.9rem;
    color: $primary;
  }

  .password-icon {
    position: absolute;
    top: 40px;
    right: 20px;
    font-size: 0.9rem;
  }

  .error {
    color: $error;
    border-color: $error;
  }
}
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #001540;
  font-weight: 400;
  margin-bottom: 14px;
}
