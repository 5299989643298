.record-label {
  display: flex;
  flex-direction: row;
  width: 100px;
  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.active-status {
  background: #eaf9ea;
  color: #25ad25;
  padding: 5px 13px;
  border-radius: 16px;
}
.user-table {
  overflow-x: scroll;
}
.table {
  background: white;
}

.inactive-status {
  background-color: #f6f6f9;
  color: #5c5083;
  padding: 5px 10px;
  border-radius: 16px;
}

.container {
  background: #ffffff;
  padding: 20px;
  margin-top: 40px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      margin: 20px 0;
    }
  }
  .payment-method {
    display: flex;
    justify-content: center;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.action-btn {
  width: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    cursor: pointer;
  }
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    cursor: pointer;
    margin-left: 20px;
  }
}
.logo {
  display: flex;
  flex-direction: row;
  width: 100px;
  align-items: center;
  img {
    width: 25px;
    margin-right: 10px;
    border-radius: 16px;
  }
}
