.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  img {
    cursor: pointer;
    margin-right: 10px;
  }
}

.body {
  background: white;
  padding: 30px;
}

.field-width {
  max-width: 700px;
}

.title h3 {
  font-size: 24px;
}
.title {
  margin-bottom: 30px;
}

.subTitle {
  font-size: 14px;
  color: gray;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
