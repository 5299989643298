.container {
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;

  .inner-container {
    border-radius: 4px;

    .heading {
      font-size: 24px;
      font-weight: 500;
      color: #16054e;
      margin-bottom: 16px;
    }
  }

  .delete-button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  .record-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    > img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 16px;
    }

    > span {
      color: #16054e;
      font-size: 14px;
    }
  }

  .record-status {
    width: 55px;
    height: 24px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f9;
    color: #5c5083;
    font-size: 11px;

    &.active {
      background-color: #eaf9ea;
      color: #25ad25;
    }
  }

  :global {
    .ant-table-cell {
      color: #16054e;
      font-size: 14px;

      &:first-of-type {
        width: 350px;
        white-space: nowrap;
      }

      &:nth-of-type(2) {
        width: 180px;
      }

      &:nth-of-type(3) {
        width: 100px;
      }

      &:nth-of-type(4) {
        width: 150px;
      }

      &:nth-of-type(5) {
        width: 80px;
      }

      &:nth-of-type(6) {
        width: 120px;
      }

      &:nth-of-type(7) {
        width: 250px;
        white-space: nowrap;
      }
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 30px;
    margin-right: 10px;
    border-radius: 50px;
    height: 30px;
  }
}

.row-width {
  width: 110px !important;
}

.nav-buttons {
  margin: 30px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userDownloadModal {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  h1 {
    margin-bottom: 10px;
  }
  span {
    color: #8b82a7;
    font-size: 15px;
    text-align: center;
  }
  .modalText {
    color: #8d6cfa;
    font-weight: 500;
  }
}
