.root {
  background-color: #fff;
  padding: 20px;
}

.imageRecord {
  display: flex;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h2 {
    font-size: 20px;
  }
  button {
    width: max-content;
    padding: 0 10px;
    font-size: 14px;
    transition: 0.3s ease-in-out;
  }
}

.modalRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  .modalTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 14px;
    }
  }
  .buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    button {
      font-size: 14px;
      transition: 0.3s ease-in-out;
      height: 40px;
    }
  }
}
