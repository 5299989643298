.left {
  width: 60%;
  background: #ffffff;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px 0px 20px;
}
.left-container {
  display: flex;
  justify-content: center;
}

.left-main {
  width: 70%;
}

.left-main-auto {
  width: auto;
}

.logo-container {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: #5c5083;
    color: #8d6cfa;
    &:hover {
      cursor: pointer;
    }
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  background: #f8f8fd;
  padding: 0px 0px 0px 0px;
}

.layout-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
}

//medium screens
@media screen and (max-width: 992px) {
  .left {
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-main {
    width: 100%;
  }
  .right {
    display: none;
  }
}

//small screens
@media screen and (max-width: 600px) {
  .left {
    width: 100%;
    background: #ffffff;
    display: block;
  }
  .left-container {
    display: block;
    width: 100%;
  }
  .left-main {
    width: 100%;
  }
  .right {
    display: none;
  }
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header {
    height: 250px;
    margin-bottom: 30px;
    width: 80%;
    h1 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 30px;
    }
    span {
      color: #5c5083;
      font-size: 17px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .modal-button {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100px;
    .button-style {
      width: 200px;
      font-weight: 600;
      font-size: 14px;
      margin-right: 10px;
      height: 60px;
    }
  }
}
