.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  button {
    height: 45px;
    font-size: 15px;
    padding: 0 20px;

    width: max-content;
  }
}

.c-image-uploaded {
  width: 240px;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-company-upload {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}
.modalRoot {
  h1 {
    margin-bottom: 20px;
  }
  .modalField {
    margin-bottom: 20px;
    width: 500px;
  }
}
.numberField {
  width: 500px;
  background-color: #fafafa;
  height: 40px;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 95%;
  margin-bottom: 20px;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 11px;
    color: #8b82a7;
  }
}

.record-name {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
.record-label {
  display: flex;
  flex-direction: row;
  width: 100px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.table {
  overflow-x: auto;
  background-color: #fff;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  .modalTop {
    margin-bottom: 40px;
    h1 {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .buttons {
    display: flex;
    gap: 20px;
    button {
      height: 45px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.recordImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
