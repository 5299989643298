.container {
  background: #ffffff;
  margin: 30px 0;
  padding: 30px;
}
.search-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category {
  margin-top: 40px;
}

.button {
  width: auto;
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  transition: 0.3s;
  margin-left: 10px;
  font-weight: 500;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 24px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.no-news {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 192px;
  margin: 30px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: 100%;
  h1 {
    color: black;
  }
}
