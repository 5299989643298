.top {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    padding: 0 10px;
    width: max-content !important;
  }
}
.modal {
  h1 {
    margin-bottom: 20px;
  }
}

.table {
  margin-top: 10px;
  background-color: white;
  padding: 20px;
  h1 {
    margin: 20px;
    font-size: 24px;
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.dates {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.record-name {
  display: flex;
  flex-direction: row;
  min-width: 100px !important;
  cursor: pointer;
}

.loading {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
  }
}

.allUser {
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    padding: 0 20px;
    transition: 0.5s ease-in-out;
    width: max-content;
  }
}

.field {
  margin-bottom: 20px;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  width: 100%;
  display: flex;
  height: 64px;
  margin-top: 20px;
  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.user-table {
  overflow-x: scroll;
}
.table {
  background: white;
}

.nav-buttons {
  margin: 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin-bottom: 20px;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.upload {
  max-width: 300px;
  min-height: 70px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #16054e;
  }
}
.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-file {
  visibility: hidden;
}
.form {
  margin-top: 30px;
}
.warning {
  color: red;
  padding-left: 10px;
  padding-top: 10px;
}
.audio-file {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  audio {
    margin-bottom: 10px;
  }
}
.uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadSec {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
