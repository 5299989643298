.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.container {
  background: #ffffff;
  padding: 30px;
  h1 {
    margin-bottom: 20px;
  }
}
