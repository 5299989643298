.ant-input {
  height: 40px;
  background: #f8f8fd;
  border-radius: 5px;
}
.ant-input:hover,
.ant-input:focus {
  border-color: #8d6cfa;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ded7f6;
  border-radius: 5px;
  background-color: #f8f8fd;
  min-height: 40px;
}

.ant-select:not(.ant-select-customize-input):hover .ant-select-selector,
.ant-select:not(.ant-select-customize-input):focus .ant-select-selector {
  border-color: #8d6cfa;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-suffix {
  display: inline-flex;
}

.db-calendar-picker-cell-inner {
  font-size: 14px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: transparent;
  color: #16054e;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8d6cfa;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  background: #8d6cfa;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 0;
}

.ant-picker {
  border: 1px solid #ded7f6;
  border-radius: 8px;
  background-color: #f8f8fd;
  min-height: 40px;
}

.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: none;
}
