@mixin title {
  margin-top: 60px;
  margin-bottom: 20px;
  color: #16054e;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}
@mixin subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 24px;
  color: #5c5083;
  margin-bottom: 20px;
}

@mixin header {
  color: #16054e;
  font-size: 29px;
  line-height: 48px;
  font-weight: 600;
}

@mixin subHeader {
  color: #5c5083;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin purpleSubHeader {
  color: #8d6cfa;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  font-weight: 400;
}
