.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  img {
    cursor: pointer;
    margin-right: 10px;
  }
}

.body {
  background: white;
  padding: 30px;
}

.field-width {
  width: 730px;
}
.input-file {
  visibility: hidden;
}
.video-upload {
  margin-bottom: 30px;
}
.upload {
  max-width: 730px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  span {
    font-size: 20px;
    font-weight: 500;
    color: #16054e;
  }
}
.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-button {
  margin-top: 20px;
  color: red;
  background: none;
  width: 170px;
  height: 40px;
  font-weight: 500;
  border: 1px solid red;
  transition: ease-in-out 0.4s;
  margin-left: 10px;
  font-size: 14px;
}

.delete-button:hover {
  color: white;
  background-color: red;
  border: none;
}
.startup-container {
  background: white;
  padding: 20px;
  margin-top: 30px;
}

.startup-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  h1 {
    font-size: 24px;
  }
}
.no-data {
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 32px;
  }
  span {
    color: grey;
    font-size: 20px;
    font-weight: 500;
  }
}
