.container {
  background: #ffffff;
  padding: 30px;
}
.header {
  h1 {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 500;
    color: #16054e;
  }
}

.pannel {
  font-size: 18px;
  font-weight: bold;
  margin: 30px auto;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.row {
  line-height: 40px;
  margin-bottom: 40px;
  .switches {
    width: 100%;
    justify-content: space-between;
    font-weight: 400;
    text-align: left;
    .form-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 16px;
      margin: 10px 0;
      padding: 0 20px;
    }
    hr {
      border: 1px solid #f0eded;
    }
  }
}
