@import '../../globalstyles/variables';

.nav {
  background-color: $primaryDark;
  width: 220px;
  height: 100%;
  min-height: 100vh;
  padding: 36px 16px;
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 200;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    top: 50px;
    left: -100vw;
    transition: left 0.4s ease-in-out;
    padding-bottom: 80px;
    min-height: 100%;

    &.show {
      left: 0;
    }
  }

  .logo {
    padding: 0px 19px;
  }

  .navLinks {
    margin: 80px 0px 131px;
    width: 100%;
    flex: 1;
    > li {
      width: 100%;
      margin-bottom: 30px;
      position: relative;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  .navEnd {
    margin-top: auto;
    width: 100%;

    .dropdown {
      img {
        object-fit: contain;
      }
    }
  }
}
.logout {
  background: $primaryDark;
  color: white;
  width: 80%;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid $primary;
  border-radius: 16px;
  transition: 0.5s;
  margin-left: 25px;
}
.logout:hover {
  background: $primary;
  border: 1.5px solid $primaryDark;
}

.subNav {
  margin-left: 40px;
}
