.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label {
    margin-bottom: 12px;
  }

  :global(.ant-form-item) {
    width: 100%;
  }
}

.inputInnerWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  img {
    height: 24px;
  }
}

.danger {
  color: red;
}

.buttonsWrapper {
  display: flex;
  gap: 12px;
}