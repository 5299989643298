.container {
	padding: 10px;

	.header {
		display: flex;
		align-items: center;

		> button {
			background-color: transparent;
			width: fit-content;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		> h1 {
			color: #8b82a7;
			font-size: 24px;
			font-weight: 400;
			margin-left: 20px;

			span {
				color: #16054e;
				font-weight: 500;
				text-transform: capitalize;
			}
		}

		.right {
			display: flex;
			margin-left: auto;

			.button {
				width: 164px;
				height: 40px;
				font-size: 15px;
				font-weight: 600;
				transition: 0.3s;
				margin-left: 16px;
				display: flex;
				align-items: center;
				justify-content: center;

				&:nth-of-type(2) {
					width: 194px;
				}
			}
		}
	}

	.section {
		margin: 20px 0;
		width: 100%;
		display: flex;

		align-items: center;
		justify-content: center;
	}

	.investor-toggler {
		background: #ffffff;
		padding: 4px;
		width: 100%;
		display: flex;
		padding: 0 30px;
		height: 64px;
	}

	.nav-link {
		font-size: 14px;
		line-height: 24px;
		margin-right: 20px;
		display: flex;
		padding: 4px 4px;
		transition: 0.3s;
		justify-content: center;
		border-bottom: 4px solid #ffffff;
		font-weight: 500;
		align-items: center;
		cursor: pointer;
		color: #8b82a7;
	}

	.nav-link:hover {
		color: #8d6cfa;
	}

	.active-toggle {
		color: #8d6cfa;
		border-color: #8d6cfa;
	}
}
