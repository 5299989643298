.header {
  color: #16054e;
  font-size: 24px;
  margin-top: 40px;
  font-weight: 500;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 35px 0 48px 0;
  label {
    margin-left: 15px;
  }
  input[type='checkbox'] {
    width: 14px !important;
    height: 14px !important;
    .input-number {
      width: 340px;
      padding: 4px;
      border-radius: 8px;
      background-color: #f8f8fd;
      border: 1px solid #ded7f6;
      &:hover,
      &:focus {
        border-color: #8d6cfa;
      }
    }

    margin: 5px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    font-size: 0.8em;
    text-align: center;
    line-height: 1em;
    border: 1px solid #16054e;
    background: white;
  }

  input[type='checkbox']:checked:after {
    content: '\2714';
    color: #ffffff;
    background: #8d6cfa;
    width: 30px !important;
    outline: none !important;
    padding: 0 2px 1.5px 3.8px;
  }
}

.form {
  margin: 24px 0 0 0;
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.container {
  background: #ffffff;
  padding: 10px 0 20px 30px;
}

input[type='checkbox']:hover {
  cursor: pointer;
}
.field-width {
  width: 350px;
  margin-bottom: 25px;
}
.input-number {
  width: 350px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
.warning {
  color: red;
  margin: 10px;
  font-size: 15px;
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h2 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}
