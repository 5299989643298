.top {
  margin-bottom: 20px;
  h1 {
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.contents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  align-items: flex-start;
  padding: 0 30px;
  width: 100%;
  max-height: 400px;
  margin-left: 20px;
}

.button {
  display: flex;
  height: 50px;
  font-size: 16px;
  color: #353434;
  font-weight: 600;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}

.receipt {
  max-height: 800px;
  height: 800px;
  padding: 10px;
  img {
    width: 100%;

    height: 800px;
    object-fit: contain;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.content {
  min-width: 200px;
  margin-bottom: 40px;
  h3 {
    font-size: 18px;
  }
  p {
    color: #9f9f9f;
    font-size: 16px;
  }
}

.modalButtons {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
