.root {
  margin-top: 20px;
  background: #fff;
}
.optionSection {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createButton {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px;
  button {
    margin-right: 20px;
    transition: 0.3s;
  }
}

.toggler {
  background: #ffffff;
  padding: 4px 10px;
  margin-left: 25px;
  width: max-content;
  display: flex;
  height: 64px;
  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 20px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active {
    color: #8d6cfa;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    // border-color: #8d6cfa;
  }
}

.button {
  width: auto;
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  transition: 0.3s;
  font-weight: 500;
}
.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.newsCards {
  margin-top: 30px;
  padding: 20px;
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.emptyData {
  display: flex;
  width: 100%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 30px;
  }
}
