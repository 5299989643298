.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  img {
    width: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.form {
  background: white;
  padding: 20px;
}

.breakdown {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 10px;

  width: 100%;
}

h2 {
  color: #16054e;
  font-size: 14px;
  font-style: normal;
  margin-top: 20px;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  margin-bottom: 10px;
  text-transform: uppercase;
}

.width {
  width: 500px;
  margin-bottom: 20px;
}

.fees {
  margin-bottom: 30px;
}

.display {
  display: flex;
  gap: 30px;
  margin: 0 20px;
}
