.container {
	margin-top: 40px;
	padding: 24px;
	background: #fff;
	border-radius: 8px;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;

		> h2 {
			font-weight: 500;
			font-size: 24px;
			color: #16054e;
		}

		.actions {
			display: flex;
			align-items: center;

			.search {
				height: 40px;

				> input {
					height: 100%;
				}
			}

			.dropdown-btn {
				margin-left: 24px;
				background-color: #fff;
				border: 1px solid #ded7f6;
				border-radius: 32px;
				width: 134px;
				height: 40px;
				color: #16054e;
				font-size: 14px;
				font-weight: 500;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}
		}
	}

	.record-label {
		display: flex;
		align-items: center;

		> img {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin-right: 16px;
		}

		> span {
			color: #16054e;
			font-size: 14px;
		}
	}

	:global {
		.ant-table-cell {
			color: #16054e;
			font-size: 14px;

			&:first-of-type {
				width: 300px;
			}

			&:nth-of-type(2) {
				width: 200px;
			}

			&:nth-of-type(3) {
				width: 250px;
			}

			&:nth-of-type(4) {
				width: 200px;
			}
		}
	}

	.button {
		width: 99px;
		height: 32px;
		font-weight: 700;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.nav-buttons {
	margin: 30px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  }
  
  .button {
	width: auto;
	padding: 5px 15px;
	height: 40px;
	font-size: 16px;
	transition: 0.3s;
	font-weight: 500;
  }
  .page-number {
	margin: 0 20px;
	color: #8d6cfa;
	font-size: 20px;
	font-weight: bold;
	border-radius: 50%;
  }

.record-label {
	display: flex;
	align-items: center;
}

.drag-icon{
	opacity: 0;
}

.drag-icon:hover{
	opacity: 1;
	display: flex;
	flex-direction: column-reverse;
	background: #8D6CFA;
	color: #fff;
	width: 30px;
	height: 50px;
	border-radius: 8px;
	justify-content: space-between;
	padding: 3px 0;
	font-weight: 500;
}

.action{
	display: flex;
	justify-content: space-between;
}

.interest-icon{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 20px;
	margin-left: 20px;
}

.loading {
	display: flex;
	flex-direction: column;
	margin-top: 259px;
	justify-content: center;
	align-items: center;
  }
  .no-update {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 450px;
	h1 {
	  font-size: 32px;
	}
	span {
	  font-size: 18px;
	  color: #8b82a7;
	}
  }


  .modal-container {
	display: flex;
	flex-direction: column;
	height: 300px;
	justify-content: center;
	align-items: center;
	h1 {
	  font-size: 25px;
  
	  font-weight: 500;
	  text-align: center;
	  margin-bottom: 30px;
	}
	.modal-button {
	  font-size: 18px;
	  margin-right: 10px;
	  width: 150px;
	  height: 50px;
	  transition: 0.3s;
	  font-weight: bold;
	}
  }