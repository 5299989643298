.root {
  background-color: #fff;
  padding: 20px;
}

.table {
  margin-top: 20px;
}

.form {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  button {
    font-size: 15px;
    font-weight: 500;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copy-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: rgb(113, 113, 179);
  .tooltip {
    visibility: hidden;
    background-color: #8b82a7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 80px;
    cursor: pointer;
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.copy-label:hover .tooltip {
  visibility: visible;
}
