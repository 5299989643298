.body {
  background-color: #fff;
  padding: 30px;
  margin: 20px 0;
}
.button-wrapper {
  margin: 20px 0;
}
.preview-button {
  margin-right: 20px;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 20fr);
}

.width {
  width: 400px;
  margin: 20px;
}
.header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 10px;
    width: 17px;
    cursor: pointer;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.switch {
  margin-left: 20px;
}
.upload {
  max-width: 700px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  span {
    font-size: 16px;
    font-weight: 400;
    color: #16054e;
  }
}
.input-file {
  visibility: hidden;
}

.pdf-height {
  max-height: 100vh;
  overflow-y: scroll;
}
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploads {
  display: flex;
  flex-direction: row;
  gap: 50px;
  p {
    font-size: 16px;
    display: flex;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    span {
      color: red;
      padding-left: 5px;
    }
  }
}
