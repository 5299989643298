.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  div {
    display: flex;
    gap: 10px;
    button {
      width: max-content;
      padding: 0 10px;
      height: 45px;
      font-size: 15px;
      min-width: 100px;
    }
  }
}

.languageVersions {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .language {
    margin-bottom: 20px;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  h1 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
  }
  button {
    width: max-content;
    padding: 0 10px;
    font-size: 15px;
  }
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  display: flex;
  height: 64px;
  margin-top: 30px;
  margin-bottom: 30px;
  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.filterSection {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-bottom: 10px;
  .txButton {
    width: max-content;
    padding: 0 10px;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .filter {
    margin-left: 20px;
    display: flex;
    padding: 8px 16px !important;
    height: 50px;
    cursor: pointer;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;
    transition: 0.5s ease-in-out;
    border: 1px solid #ded7f6;
    border-radius: 32px;
    align-items: center;
    h2 {
      color: #16054e;
      font-size: 14px;
      font-style: normal;
      margin: 0;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
  .filter:hover {
    opacity: 0.5;
  }
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
}

.topNav {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;
  }
}
