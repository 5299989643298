$primary: #8d6cfa;
$secondary: #ffffff;
$fade: #b9b4ca;
$dark: #16054e;
$light: #8b82a7;
$main: #f8f8fd;
$shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
$error: #ee0020;
$bgPurple: #efebfe;
$greyBorder: #ded7f6;
$bodyBg: #e5e5e5;
$secondaryLight: #5C5083;
$pink: #FFEEF0;
$green: #EAF9EA;
$gold: #f5a623;
$lightGold: #fef4e5;


// Admin
$primaryDark: #060125;
