.container {
  background: #ffffff;
  padding: 40px;
  min-height: 400px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.button {
  font-weight: 500;
  margin-right: 10px;
  font-size: 13px;
  transition: 0.6s;
  width: 120px;
}
.header {
  display: flex;
  margin-bottom: 40px;
  flex-direction: row;
  align-items: center;
  h1 {
    font-weight: 500;
    font-size: 32px;
    color: #16054e;
  }
  img {
    cursor: pointer;
    height: 20px;
    margin-right: 20px;
    width: 20px;
  }
}
