.container {
  background: #ffffff;
  padding: 30px;
}

.topics {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .topic-card {
    margin: 10px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    h1 {
      font-size: 32px;
    }
    span {
      font-size: 18px;
      color: #8b82a7;
    }
  }
