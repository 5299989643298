.content {
  background: #fff;
  padding: 40px;
  margin-top: 40px;
}
.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}

.fieldWidth {
  width: 700px;
  margin-bottom: 20px;
}
.date {
  display: flex;
  gap: 100px;
}

.button {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
  button {
    width: max-content;
    padding: 0 20px;
  }
}
.modal {
  height: auto;
  text-align: center;
  .type {
    color: red;
    font-weight: bold;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  button {
    width: auto;
    padding: 8px 16px;
  }

  span {
    text-align: center;
    font-size: 16px;
  }
}
.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
  button {
    width: 150px;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
