.root {
}

.nav {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-right: 10px;
    height: 16px;
    cursor: pointer;
  }
}

.form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 10px;
  width: 100%;
}

.content {
  background: #fff;
  margin-top: 10px;
  padding: 20px;
}

.updateStatus {
  display: block;
  h1 {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.profile {
  display: flex;
  align-items: center;
  margin-top: 10px;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 11px;
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 17px;
    font-weight: 600;
  }
  div {
    cursor: pointer;
    border-radius: 8px;
  }
  div:hover {
    opacity: 0.6;
  }
}
.largeWidth {
  // width: 270px;
  margin-bottom: 20px;
}

.loading {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 450px;
  margin-bottom: 30px;
  p {
    border-bottom: 1px solid #ded7f6;
    padding: 10px 0;
    font-size: 13px;
  }
  strong {
    margin-right: 10px;
  }
}

.details {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 70px;
  margin-bottom: 40px;
}

.tag {
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
}

.copy {
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    opacity: 0.6;
    background: #ded7f6;
  }
}
