.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  margin: 30px 0;
  padding: 10 20px;
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 94%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 11px;
    color: #8b82a7;
  }
}

.user-table {
  overflow-x: scroll;
}
