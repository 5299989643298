@import "globalstyles/mixings";
@import "globalstyles/variables";

.share-button {
	display: flex;
	align-items: center;
	padding: 0px;
	background-color: transparent;

	span {
		color: $light;
		font-size: 14px;
		font-weight: 600;
		margin-left: 10px;
	}
}
