.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 10px;
    cursor: pointer;
  }
}
.body {
  background: white;
  margin-top: 20px;
  padding: 20px;
}
.form {
  margin-top: 20px;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  margin-bottom: 30px;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.key-facts {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 30px 0;
  padding: 20px;
  column-gap: 10px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  img {
    margin-left: 30px;
    height: 24px;
    width: 24px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
  }
}

.icons {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;

  img {
    width: 30px;
    margin-right: 10px;
    height: 30px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.c-level {
  color: #8d6cfa;
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  h1 {
    font-size: 32px;
    margin-top: 30px;
  }
  span {
    font-size: 18px;
    text-align: center;
    color: #90a0c2;
    padding-bottom: 20px;
  }
}

.m-version {
  font-size: 20px !important;
  font-weight: bold;
  color: black !important;
  padding: 0 10px;
}
.button {
  height: 45px;
  width: 180px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  margin-top: 40px;
}
.m-warning {
  color: red !important;
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  height: 64px;

  > span {
    font-size: 13px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 2px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.filterSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 20px;
    margin-top: 10px;
  }
  span {
    color: #8b82a7;
  }
  margin: 20px 0;
}

// FORCE SIGN OUT

.signOutRoot {
  padding: 22px 40px;
  background: #fff;
  margin-top: 20px;
  h1 {
    font-size: 25px;
  }
  span {
    font-size: 15px;
    color: #8b82a7;
  }
  .form {
    margin-top: 30px;
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 25px;

    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-button {
    font-size: 18px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}
.top {
  padding: 22px 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 25px;
  }
  p {
    font-size: 15px;
    color: #8b82a7;
  }
}
.documentHeader {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 40px;
}

.documentFieldWidth {
  width: 400px;
  margin-bottom: 20px;
}
.documentNumber {
  width: 400px;
  border-radius: 4px;
  height: 40px;
  background: #f9f9f9;
}
.yearly {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  width: 500px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  p {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  div {
    display: flex;
    gap: 20px;
  }
  .numberField {
    border-radius: 4px;
    margin-left: 10px;
    height: 40px;
    background: #f9f9f9;
  }
}
.numberSelect {
  max-width: 200px;
}

.timezone {
  max-width: 300px;
  margin-bottom: 20px;
}

.yearlyFlex {
  display: flex;
  flex-direction: row;
  border: 1px red;
}

.form {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.form2 {
  padding: 20px;
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h2 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}

.proField {
  width: 500px;
  margin-bottom: 20px;
}

.translateField {
  display: flex;
  align-items: center;
  gap: 20px;
}

.number {
  border-radius: 4px;
  margin-left: 10px;
  height: 40px;
  width: 300px;
  background: #f9f9f9;
}

.flexed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row {
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-bottom: 20px;
  .formItem {
    width: 65%;
    justify-content: space-between;
    font-weight: 400;
    display: flex;
    flex-direction: row-reverse;
    text-align: left;
    padding: 10px 20px;
    transition: ease-in-out 0.3s;
    .switchContainer {
      display: flex;
      width: 100%;

      justify-content: space-between;
      font-size: 16px;
    }
    &:hover {
      cursor: pointer;
      background: #eef1f7;
    }
  }
}

.tip {
  background: #d9d5e6;
  display: flex;
  padding: 10px;
  align-items: start;
  gap: 20px;
  margin: 20px 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 12px;
  p {
    color: black;
    font-style: italic;
    font-weight: 500;
  }
}

.mHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  p {
    opacity: 0.5;
  }
}

.switch {
}
