.root {
  padding: 40px;
  background-color: white;
  .top {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    justify-content: space-between;
    align-items: center;
  }
}

.fieldWidth {
  width: 400px;
  margin-bottom: 20px;
}

.nav {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}

.fees {
  max-width: 450px;
  h3 {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 40px;
  }
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: max-content;
  margin-bottom: 20px;
  margin-left: 25px;
  padding: 5px;
  border-radius: 8px;
  h5 {
    margin-left: 5px;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.feesHeader {
  margin-bottom: 20px;
}
