.container-header {
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  justify-content: space-between;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.startups {
  color: #8b82a7;
  font-size: 28px;
}
.details {
  color: #16054e;
  font-weight: 500;
}
.button-style {
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  margin-right: 10px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-width {
  max-width: 340px !important;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.content {
  margin-top: 25px;
  background-color: #ffffff;
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  .right-grid {
    width: 72%;
  }
  .left-grid {
    display: flex;
    width: 28%;
    flex-direction: column;
    margin-top: 40px;

    align-items: center;
    justify-content: space-between;
  }
  .button-red {
    color: red !important;
    padding: 10px 25px;
    font-size: 15px;
    width: 200px;
    height: 40px;
    margin-top: 20px;
    transition: 0.4s;
    border-color: red;
    font-weight: 500;
  }
  .button-red:hover {
    background-color: red;
    color: white !important;
  }
}

.ql-editor {
  width: 85%;
  background: #f8f8fd;
  border-radius: 5px;
}

.about-company {
  h1 {
    font-size: 24px;
    margin-top: 65px;
    font-weight: 500;
    margin-bottom: 24px;
    color: #16054e;
  }
  .text-editor {
    width: 90%;
  }
}

.category {
  margin-top: 20px;
  h2 {
    font-size: 24px;
    margin-top: 65px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #16054e;
  }
}
.select-form {
  display: flex;
  flex-direction: column;
}

.overview {
  margin-right: 30px;
  padding-bottom: 40px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.form-item {
  label {
    display: block;
    margin-top: 10px;
  }
}

.form-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-number {
  width: 340px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
}

.input-number:hover,
.input-number:focus {
  border-color: #8d6cfa;
}
.input-modal-number {
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
}

.input-modal-number:hover,
.input-modal-number:focus {
  border-color: #8d6cfa;
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;

  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.add-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 12px 0;
  cursor: pointer;

  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 18px;
  }
}

.delete {
  img {
    cursor: pointer;
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.header {
  font-size: 24px;
  color: #16054e;
  margin-top: 40px;
  margin-bottom: 13px;
}

.small-header {
  font-size: 14px;
  color: #16054e;
  font-weight: 700;
  margin-top: 13px;
  margin-bottom: 13px;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
.modal-button {
  width: 80%;
  height: 56px;
  font-weight: 600;
  margin: 0 40px;
  margin-top: 40px;
  align-items: center;
  font-size: 14px;
}
.modal-container {
  h1 {
    font-size: 24px;
    font-weight: 600;
    color: #16054e;
    margin-bottom: 40px;
  }
}
.modal-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.form {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 30px;
}
.deleteFund {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-right: 5px;
  img {
    cursor: pointer;
  }
  img:hover {
    opacity: 0.7;
  }
}

.fund-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
