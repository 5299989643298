.company-image {
	width: 40px;
	height: 40px;
}

.company-upload {
	width: 240px;
	height: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #ede9fa;
	border-radius: 16px;

	div {
		width: 125px;
		font-size: 16px;
		text-align: center;
		color: #16054e;
		font-weight: 500;
	}
}

.large-company-upload {
	width: 796px;
	height: 320px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #ede9fa;
	border-radius: 16px;
	cursor: pointer;

	div {
		width: 300px;
		font-size: 16px;
		text-align: center;
		color: #16054e;
		font-weight: 500;
	}
}

.image-uploaded {
	width: 240px;
	height: auto;

	img {
		width: 240px;
		height: 240px;
		border-radius: 16px;
	}
}

.info-banner {
	background-color: #fef4e5;
	border-radius: 8px;
	padding: 24px;
	height: auto;
	margin-bottom: 40px;
	display: flex;
	align-items: center;

	> img {
		width: 22px;
		height: 19px;
	}

	> p {
		color: #16054e;
		font-size: 14px;
		line-height: 24px;
		margin-left: 17px;
	}
}

.action-buttons {
	display: flex;
	margin-bottom: 24px;
}

.upload-button {
	font-weight: 600;
	width: 159px;
	height: 40px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.preview-button {
	font-weight: 600;
	width: 159px;
	height: 40px;
	font-size: 14px;
	margin-left: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button-red {
	color: red !important;
	font-size: 14px;
	width: 142px;
	height: 40px;
	transition: 0.4s;
	border-color: red;
	font-weight: 600;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button-red:hover {
	background-color: red;
	color: white !important;
}

.large-image-uploaded {
	width: 796px;
	height: 876px;

	.large-image-uploaded-container {
		width: 796px;
		height: 876px;
		border-radius: 8px;
		background-color: #f8f8fd;
		padding: 24px;

		> embed {
			width: 100%;
			height: 828px;
			border-radius: 8px;
			object-fit: contain;
		}
	}
}
