.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-bottom: 40px;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}
.crumb {
  background: #6d47ee;
  height: 6px;
  margin-bottom: 40px;
  border-radius: 16px;
}
.section {
  margin: 20px 0;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.investor-toggler {
  background: #ffffff;
  padding: 4px;
  // margin-left: 0px;
  width: 100%;
  display: flex;
  padding: 0 10px;
  height: 64px;
  overflow-x: scroll;
}

.nav-link {
  font-size: 13px;
  line-height: 24px;
  margin-right: 5px;
  display: flex;
  padding: 4px 4px;
  transition: 0.3s;
  justify-content: center;
  border-bottom: 4px solid #ffffff;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: #8b82a7;
}

.nav-link:hover {
  color: #8d6cfa;
}

.active-toggle {
  color: #8d6cfa;
  border-color: #8d6cfa !important;
  margin-bottom: 10px;
}
