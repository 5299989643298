.profileImg {
  width: 40px;
  height: 40px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
