.logo {
  width: 109px;
  height: 32px;
}
.logo img {
  width: 100%;
  height: 100%;
}

.logo:hover {
  cursor: pointer;
}
