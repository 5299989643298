.container {
  padding: 20px;
  .modal-header {
    h1 {
      width: 760px;
      margin-bottom: 10px;
      color: #16054e;
      font-size: 24px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      color: #8b82a7;
    }
    span {
      color: #8d6cfa;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
.dragger {
  margin: 40px 0;
  .dragger-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    flex-direction: column;
    span {
      width: 50%;
      margin-top: 15px;
    }
  }
}
.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  margin-right: 15px;
  height: 40px;
  transition: 0.4s;
  font-weight: 500;
}
