.company-image {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.company-upload {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  cursor: pointer;

  div {
    width: 125px;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 500;
  }
}
.large-company-upload {
  width: 730px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  cursor: pointer;

  div {
    width: 300px;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 500;
  }
}
.thumbail-image {
  width: 530px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  cursor: pointer;

  div {
    width: 300px;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 500;
  }
}
.icon-image {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  cursor: pointer;
  div {
    padding: 10px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 500;
  }
}

.image-uploaded {
  width: 240px;
  height: auto;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.button-red {
  color: red !important;
  padding: 10px 25px;
  font-size: 15px;
  width: 250px;
  height: 40px;
  margin-top: 20px;
  transition: 0.4s;
  border-color: red;
  font-weight: 500;
}
.button-red:hover {
  background-color: red;
  color: white !important;
}
.thumbail-upload {
  width: 530px;
  height: 420px;
  img {
    width: 530px;
    height: 320px;
    border-radius: 16px;
  }
}
.large-image-uploaded {
  width: 730px;
  height: 420px;
  img {
    width: 730px;
    height: 320px;
    border-radius: 16px;
  }
}

.c-image-uploaded {
  width: 459px;
  height: 320;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.c-company-upload {
  width: 459px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}

.top-buttons {
  width: 80%;
  margin-bottom: 40px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .button {
    padding: 10px 20px;
    width: 200px;
    transition: 0.5s;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
  .delete-button {
    color: red !important;
    padding: 10px 20px;
    font-size: 15px;
    width: 200px;
    height: 50px;
    transition: 0.4s;
    border-color: red;
    font-weight: 500;
  }
  .delete-button:hover {
    background-color: red;
    color: white !important;
  }
}
.upload-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  h3 {
    font-weight: 500;
    font-size: 18px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
  }
}
