.container {
	margin-top: 40px;
	padding: 24px;
	background: #fff;
	border-radius: 8px;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;

		> h2 {
			font-weight: 500;
			font-size: 24px;
			color: #16054e;
		}
		.date-filter{
			display: flex;
			align-items: center;
			.date-style{
				margin-left: 5px;
			}
			.filter-button{
				margin-left: 10px;
			}
		}

		.actions {
			display: flex;
			align-items: center;

			.search {
				height: 40px;

				> input {
					height: 100%;
				}
			}

			.dropdown-btn {
				margin-left: 24px;
				background-color: #fff;
				border: 1px solid #ded7f6;
				border-radius: 32px;
				width: 134px;
				height: 40px;
				color: #16054e;
				font-size: 14px;
				font-weight: 500;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}
		}
	}

	.arrow{
		display: flex;
		align-items: center;
		cursor: pointer;
		img{
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}

	.record-label {
		display: flex;
		align-items: center;

		> img {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin-right: 16px;
		}

		> span {
			color: #16054e;
			font-size: 14px;
		}
	}
	.action-buttons{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 150px;

		> img {
			cursor: pointer;
			height: 20px;
		}
	}

	:global {
		.ant-table-cell {
			color: #16054e;
			font-size: 14px;

			&:first-of-type {
				width: 300px;
			}

			&:nth-of-type(2) {
				width: 200px;
			}

			&:nth-of-type(3) {
				width: 250px;
			}

			&:nth-of-type(4) {
				width: 200px;
			}
		}
	}

	.add-button {
        width: 200px;
		font-weight: 700;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.button {
		width: 99px;
		height: 32px;
		font-weight: 700;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.input-number {
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
}

.input-number:hover,
.input-number:focus {
  border-color: #8d6cfa;
}
.modal-title{
    font-size: 24px;
    font-weight: 600;
    color: #16054e;
    text-align: center;
}
.form-input{
    display: flex;
}
.month-input{
    width: 125px;
    margin-right: 63px;
}
.year-input{
    width: 125px;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.span-element{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.company-length{
	color: #8b82a7;
	font-size: 12px;
}

.loading {
	display: flex;
	flex-direction: column;
	margin-top: 259px;
	justify-content: center;
	align-items: center;
  }
  .no-update {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 450px;
	h1 {
	  font-size: 32px;
	}
	span {
	  font-size: 18px;
	  color: #8b82a7;
	}
  }