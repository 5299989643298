.container {
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-top: 30px;
  height: auto;
  .wrapper {
    background-color: #ffffff;
    width: 100%;
    padding: 40px;
  }
}

.no-founders {
  width: 100%;
  height: 400px;
  display: flex;

  justify-content: center;
  align-items: center;
}
.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.founder-status {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.form-item {
  width: 370px;
  margin-bottom: 20px;
}
