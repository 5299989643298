.header {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  // float: right;
  background: #ffffff;
  margin: 30px 0;
  padding: 12px 20px;
}
.header div:first-child {
  min-width: 80%;
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 94%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 11px;
    color: #8b82a7;
  }
}

.stockSection {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
  width: 100%;
  align-items: flex-end;
  align-self: flex-end;
  .button {
    height: 50px;
    font-size: 15px;
    align-self: flex-end;
    font-weight: 600;
    margin-left: 20px;
    width: max-content;
    padding: 0 10px;
  }
}
.deleteIcon {
  cursor: pointer;
  transition: 0.3s;
}
.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  h1 {
    font-size: 32px;
  }
  span {
    color: #8b82a7;
    padding-bottom: 20px;
    font-size: 16px;
  }
  .deleteButtons {
    button {
      font-size: 14px;
      margin-right: 10px;
      transition: 0.3s;
      height: 45px;
    }
  }
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.name {
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}
.section {
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-toggler {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;
}
