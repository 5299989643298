.update-container {
  margin-top: 25px;
  background-color: #ffffff;
  margin: 40px 0;
  margin-left: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.header {
  margin: 5px 20px;
}

.form {
  padding: 40px;
  label {
    font-size: 20px;
  }
}

.container {
  padding: 40px;
  background-color: white;
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    justify-content: space-between;
    align-items: center;
  }
}

.button-style {
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.footer-button-style {
  padding: 10px 25px;
  font-size: 18px;
  width: 300px;
  height: 50px;
  margin-top: 10px;
  margin-top: 50px;
  transition: 0.4s;
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: center;
  width: 70%;
  align-items: center;
}

.button-style {
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
