.container {
    padding: 10px 10px 63px;
  
    .header {
      display: flex;
      align-items: center;
  
      > button {
        background-color: transparent;
        width: fit-content;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      > h1 {
        font-size: 32px;
        color: #16054e;
        font-weight: 500;
        margin-left: 20px;
      }
    }
  
    .inner-container {
      background-color: #fff;
      border-radius: 4px;
      padding: 40px;
      margin-top: 40px;


      .sub-heading {
        color: #16054e;
        font-size: 24px;
        font-weight: 500;
        width: 800px;
        margin-bottom: 24px;
      }
  
      .section {
        margin-bottom: 56px;
  
        &:nth-of-type(3) {
          margin-bottom: 24px;
        }
  
        .small-heading {
          font-size: 14px;
          color: #16054e;
          font-weight: 400;
          margin-bottom: 8px;
        }
  
        :global {
          .ant-select {
            width: 100%;
          }
  
          .ant-select-selector {
            min-height: auto;
            height: 48px;
          }
  
          .ant-select-selection-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 0px;
          }
        }
      }
  
      .input-number {
        width: 100%;
        border: 1px solid #ded7f6;
        padding: 6px;
        border-radius: 8px;
        background-color: #f8f8fd;
      }
  
      .button {
        width: 241px;
        height: 48px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .loading {
    display: flex;
    flex-direction: column;
    margin-top: 259px;
    justify-content: center;
    align-items: center;
  }
  