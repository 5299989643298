.card-container {
  background: #ffffff;
  width: 250px;
  min-width: 240px;
  height: 160px;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  .top {
    background: #f8f8fd;
    height: 96px;
    padding: 16px;
    border-radius: 8px;
    h1 {
      font-size: 24px;
      margin-bottom: 8px;
      font-weight: 600;
    }
    span {
      font-size: 14px;
      color: #8b82a7;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-left: 24px;
    h2 {
      font-size: 15px;
      margin-right: 10px;
      font-weight: 500;
      color: #8b82a7;
    }
  }
}

.active-card {
  background: #a48afa;
  width: 250px;
  min-width: 240px;
  height: 160px;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  .top {
    background: #8d6cfa;
    height: 96px;
    padding: 16px;
    border-radius: 8px;
    h1 {
      font-size: 24px;
      margin-bottom: 8px;
      color: #ffffff;
      font-weight: 600;
    }
    span {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-left: 24px;
    h2 {
      font-size: 15px;
      margin-right: 10px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.positive {
  background: #eaf9ea;
  color: #4ecc4e;
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 12px;
}
.negative {
  border-radius: 16px;
  padding: 5px 15px;
  font-size: 12px;
  color: #ee0020;
  background: #ffeef0;
}
.amount {
  font-weight: 500;
  font-size: 14px;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  padding-top: 16px;
  // padding-left: 24px;
  h2 {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
    color: #8b82a7;
  }
}
.ac-container {
  max-width: 340px;
  position: relative;
  height: 470px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 32px;
}

.ac-image {
  img {
    width: 340px;
    height: 200px;
    border-radius: 8px;
  }
}
.ac-title {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
}
.ac-logo {
  position: absolute;
  z-index: 99;
  width: 56px;
  bottom: 2px;
  left: 24px;
  height: 56px;
  border-radius: 100%;
}
.ac-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.ac-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  justify-content: space-between;
  margin-top: 25px;
  h1 {
    color: #16054e;
    font-size: 26px;
    font-weight: 600;
  }
}
.ac-series {
  .ac-f-series {
    font-size: 10px;
    font-weight: 600;
    color: #8b82a7;
    background: #eeedf1;
    margin-right: 8px;
    border-radius: 32px;
    padding: 5px 12px;
  }
  .ac-s-series {
    color: #f7b344;
    border-radius: 32px;
    padding: 5px 12px;
    font-size: 10px;
    font-weight: 600;
    background: #fef4e5;
    border-radius: 32px;
  }
}

.description {
  color: #8b82a7;
  font-size: 14px;
  font-weight: 400;
}

.thumbnails {
  margin: 12px 0;
}
.ac-body {
  padding: 10px;
}

.capital {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  h1 {
    font-size: 24px;
    color: #16054e;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
    color: #8b82a7;
  }
}
.bottom-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 220px;
}

.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 18px 10px;
  border-bottom: 1px solid #ded7f6;
  img {
    margin-right: 10px;
  }
}

.top-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.arrow {
  font-size: 150%;
}
.chart-icon {
  cursor: pointer;
  font-weight: 900;
  transform: rotate(35deg);
  margin-right: -10px !important;
  margin-top: -15px;
}
