.container {
  background: #ffffff;
  padding: 30px;
}
.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}

.button {
  height: 45px;
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  transition: 0.4s;
}
.small-width {
  width: 320px;
}

.title {
  font-size: 22px;
  color: #16054e;
  margin: 20px 0;
}
.form {
  margin-top: 25px;
}
.input-width {
  width: 720px;
}
.overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 75%;
  justify-content: space-between;
}

.profile-body {
  h1 {
    font-size: 32px;
    margin: 40px 0;
    font-weight: 500;
    color: #16054e;
  }
  .profile {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
  }
}
.cp-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .align-top {
    min-height: 50vh;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.image-style {
  width: 720px;
  height: 320px;
  border-radius: 16px;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.header {
  display: flex;
  margin-bottom: 40px;
  flex-direction: row;
  align-items: center;
  h1 {
    font-weight: 500;
    font-size: 32px;
    color: #16054e;
  }
  img {
    cursor: pointer;
    height: 20px;
    margin-right: 20px;
    width: 20px;
  }
}
.input-number {
  width: 320px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
