.signup-image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
