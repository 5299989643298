.container {
	padding: 10px;

	.header {
		> h1 {
			font-size: 32px;
			color: #16054e;
			font-weight: 500;
		}
	}

	.percentage-tab {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		margin: 40px 0;
		gap: 20px;
	}

	.verifier-toggler {
		background: #ffffff;
		padding: 4px;
		width: 100%;
		display: flex;
		padding: 0 30px;
		height: 64px;

		> span {
			font-size: 14px;
			line-height: 24px;
			margin-right: 20px;
			display: flex;
			padding: 4px 4px;
			transition: 0.3s;
			justify-content: center;
			border-bottom: 4px solid #ffffff;
			font-weight: 500;
			align-items: center;
			cursor: pointer;
			color: #8b82a7;
		}

		.active-tab {
			color: #8d6cfa;
			border-color: #8d6cfa;
		}
	}

	.sections-tab {
		margin-top: 20px;
		width: 100%;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
