.container {
  background: #fff;
  padding: 30px;
}
.form {
  margin-top: 30px;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.aml-status {
  margin-top: 20px;
}
.width {
  width: 600px;
}
