.imageProfile {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.dummyAvatar {
  width: 34px; /* Adjust as needed */
  height: 34px; /* Adjust as needed */
  border-radius: 50%;
  background-color: #f3f0fe; /* Fallback color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #5e4cc9;
  text-transform: uppercase;
  padding: 12px;
}

.rootSaver {
  display: flex;
  gap: 10px;
  flex-direction: column;
  h1 {
    font-size: 24px;
    font-weight: 500;
  }
  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .plan {
    width: 100%;
    margin-top: 20px;
    border-radius: 16px;
    background: #f2f0ff;
    box-shadow: 0 4px 8px rgba(236, 234, 230, 0.1); // Add this line for the box shadow

    border: 1px solid #f2f0ff;
    .planTop {
      padding: 10px;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      span {
        color: #5e4cc9;
        font-size: 14px;
      }
      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .planBottom {
      padding: 10px;
      background-color: #fff;
      display: flex;
      border-radius: 16px;
      align-items: center;
      gap: 10px;
      img {
        width: max-content;
        height: max-content;
      }
      p {
        font-size: 32px;
        font-weight: 500;
        margin: 0;
      }
      span {
        color: #8d94a7;
      }
    }
  }
}

.currenciesContainer {
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #f2f0ff;
  padding: 10px;
  border-radius: 16px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(236, 234, 230, 0.1); // Add this line for the box shadow
  .currency {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 10px;
    span {
      color: #8d94a7;
      font-size: 14px;
      line-height: 20px;
    }
    p {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
    }
    .coinImage {
      background-color: #f6f4ff;
      padding: 10px;
      border-radius: 50%;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .savingType {
    background-color: #f6f4ff;
    border-radius: 24px;
    padding: 5px 10px;
    width: max-content;
    p {
      font-size: 11px;
      margin: 0;
    }
  }
}

.historyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  :first-child {
    display: flex;
    width: 100%;

    align-items: center;
    button {
      width: max-content;
      color: #5e4cc9;
      border: 1px solid #f3f0fe;
      width: 100px;
      background: transparent;
      padding: 5px;
      transition: 0.4s ease-in-out;
      :hover {
        opacity: 0.2;
      }
    }
    h1 {
      font-size: 15px;
    }
  }
}

.history {
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(236, 234, 230, 0.1); // Add this line for the box shadow
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #f2f0ff;
  .time {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    .image {
      width: max-content;
      position: relative;
      background: #f6f4ff;
      padding: 10px;
      border-radius: 50%;
      span {
        background: green;
        position: absolute;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .createdAt {
      display: flex;
      flex-direction: column;
      p {
        font-size: 13px;
      }
      span {
        font-size: 13px;
        color: #8d94a7;
        text-transform: capitalize;
      }
    }
  }
  .historyAmount {
    font-size: 20px;
    font-weight: 500;
  }
}

.updateModal {
  h1 {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
  }
}

.formItem {
  margin-bottom: 20px;
}

.top {
  display: flex;
  float: right;
  margin-bottom: 20px;
  button {
    width: max-content;
    padding: 0 10px;
  }
}
