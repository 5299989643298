.section {
  margin: 20px 0;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.tabs {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;
}

.nav-link {
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  display: flex;
  padding: 4px 4px;
  transition: 0.3s;
  justify-content: center;
  border-bottom: 4px solid #ffffff;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: #8b82a7;
}

.nav-link:hover {
  color: #8d6cfa;
}

.active-toggle {
  color: #8d6cfa;
  border-color: #8d6cfa;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 10px;
    cursor: pointer;
  }
}
