.contents {
  background: #fff;
  padding: 20px;
  margin: 40px;
  p {
    margin: 20px 0;
    font-size: 18px;
    font-weight: 400;
  }
}
.fieldWidth {
  width: 700px;
  margin-bottom: 20px;
}
.option-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}
.name-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  p {
    margin-bottom: 20px;
    font-weight: 500;
  }
  h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    button {
      width: 100%;
      height: 45px;
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
}
.top {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  h1 {
    text-transform: uppercase;
    font-weight: 600;
  }
  button {
    width: max-content;
    padding: 0 15px;
    font-size: 15px;
    font-weight: 550;
  }
}

.manager {
  display: flex;
  flex-direction: row;
  width: max-content;
}

.smallWidth {
  width: 300px;
  margin-bottom: 20px;
}

.addManagers {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}
.question-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-left: 20px;
    cursor: pointer;
  }
}

.managersArray {
  max-height: 200px;
  overflow: auto;
  width: 100%;
  margin: 20px 0;
  .manager {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.line-separator {
  border: none; /* Remove default border */
  border-top: 2px solid #ccc; /* Customize the top border */
  margin: 20px 0; /* Adjust the spacing around the separator */
  width: 100%; /* Adjust the width */
}
