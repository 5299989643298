.loading {
	display: flex;
	flex-direction: column;
	margin-top: 259px;
	justify-content: center;
	align-items: center;
}
.container {
	padding: 40px;
	background-color: #fff;
	border-radius: 4px;

	.updateButton {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		button {
		  width: max-content;
		  font-size: 16px;
		  height: 40px;
		  padding: 0 10px;
		  border-radius: 12px;
		  transition: 0.3s ease-in-out;
		}
	  }
	.inner-container {
		border-radius: 4px;

		.section {
			margin-bottom: 56px;

			&:nth-of-type(3) {
				margin-bottom: 24px;
			}

			.sub-heading {
				color: #16054e;
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 24px;
			}

			.small-heading {
				font-size: 14px;
				color: #16054e;
				font-weight: 400;
				margin-bottom: 8px;
			}

			:global {
				.ant-select {
					width: 100%;
				}

				.ant-select-selector {
					min-height: auto;
					height: 48px;
				}

				.ant-select-selection-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					line-height: 0px;
				}
			}
		}

		.rank-paragraph{
			font-size: 14px;
			color: #8B82A7;
			margin-bottom: 5px;

			span{
				color: #8D6CFA;
			}
		}

		.input-number{
			width: 100%;
			border: 1px solid #DED7F6;
			padding: 6px;
			border-radius: 8px;
			background-color: #F8F8FD;
		}

		.button {
			width: 241px;
			height: 48px;
			font-weight: 600;
			font-size: 16px;
		}
	}

	.change-image-button {
		width: 240px;
		height: 48px;
		font-size: 16px;
		font-weight: 600;
		margin-top: 16px;
	}

	.icon-image-container {
		> img {
			width: 240px;
			height: 240px;
			border-radius: 16px;
		}
	}

	.banner-image-container {
		> img {
			width: 100%;
			max-width: 796px;
			height: 320px;
			border-radius: 16px;
		}
	}
}
.all-data {
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
	  width: 20px;
	  height: 20px;
	  margin-right: 10px;
	}
  }
