.tab-component {
  display: flex;
  column-gap: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.details-toggler {
  padding: 25px 4px;
  width: 20%;
  display: flex;
  flex-direction: column;
  height: 99%;
  margin-left: 20px;
  border-right: 1px solid #f2f2f2;
  row-gap: 20px;

  > button {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-right: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    background: none;
    padding: 4px;
    // margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.tabs {
  max-width: 90%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.generalRoot {
  width: 100%;

  h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.buttons {
  display: flex;
  gap: 20px;
  button {
    width: max-content;
    min-width: 100px;
    padding: 0 10px;
  }
}

.field {
  margin-bottom: 20px;
}

.numberField {
  width: 100%;
  background: #f8f8fd;
  height: 40px;
  border-radius: 4px;
}

.topNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  h1 {
    font-size: 22px;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
