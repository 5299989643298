.fieldWidth {
  width: 700px;
  margin-bottom: 20px;
}

.form {
  background: #fff;
  padding: 40px;
}
.top {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
