.form {
  background: white;
  padding: 20px;
  h1 {
    margin-bottom: 20px;
  }
  .field {
    width: 700px;
    margin-bottom: 15px;
  }
  .fieldFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 67%;
    align-items: center;
    justify-content: space-between;
  }
  .smallWidth {
    width: 310px;
    margin-bottom: 15px;
  }
  h2 {
    margin: 40px 0 20px;
    font-size: 16px;
  }
  .number {
    width: 300px;
    margin-bottom: 15px;
  }
}

.input-number {
  width: 310px;
  padding: 4px;
  border-radius: 8px;
  height: 45px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}
