.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 20px;
  h1 {
    color: #16054e;
    font-size: 32px;
    font-weight: 500;
  }
}

.title {
  color: #16054e;
  font-size: 20px;
  margin-bottom: 20px;
}

.channels {
  display: flex;
  justify-content: space-between;
  align-items: start;
  column-gap: 20px;
}
.warning {
  color: red;
  font-size: 16px;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}

.button-style {
  padding: 10px 25px;
  font-size: 15px;
  width: auto;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 500;
}
.notifications-toggler {
  background: #ffffff;
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  padding: 0 30px;
  height: 64px;

  > span {
    font-size: 16px;
    margin-right: 20px;
    display: flex;
    padding: 0 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.form {
  margin-top: 20px;
}
.modalHeader {
  font-size: 24px;
  color: #16054e;
}
.modalForm {
  margin-top: 20px;
  .input {
    width: 100%;
  }
}
