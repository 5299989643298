.add-risks {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}
.question-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-left: 20px;
    cursor: pointer;
  }
}
.inputNumber {
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
}

.header {
  margin: 40px 0;
  h1 {
    color: #16054e;
    font-size: 32px;
    font-weight: 500;
  }
  span {
    color: #8b82a7;
    font-size: 14px;
    font-weight: 400;
  }
}
.title {
  color: #16054e;
  font-size: 21px;
  margin-bottom: 28px;
  font-weight: 500;
}
.risks {
  margin-bottom: 56px;
}
.container {
  background: #ffffff;
  padding: 10px 0 30px 30px;
}
