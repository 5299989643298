.container {
  background: white;
  padding: 20px;
}
.upload {
  max-width: 500px;
  min-height: 250px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #16054e;
  }
}
.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-file {
  visibility: hidden;
}
.form {
  margin-top: 30px;
}
.warning {
  color: red;
  padding-left: 10px;
  padding-top: 10px;
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.audio-file {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  audio {
    margin-bottom: 10px;
  }
}
.upload-image {
  margin: 20px 0;
}
.footer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}
.uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin: 20px;
}
.input {
  margin-top: 20px;
  width: 700px;
}

.delete-button {
  color: red !important;
  padding: 10px 25px;
  font-size: 15px;
  width: 250px;
  height: 40px;
  margin-top: 20px;
  transition: 0.4s;
  border-color: red;
  font-weight: 500;
}

.delete-button:hover {
  background-color: red;
  color: white !important;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
