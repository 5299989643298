.container {
  background: #ffffff;
  padding: 30px;
}
.saved-reports {
  h1 {
    color: #16054e;
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.report {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.news {
  margin-top: 40px;
  h1 {
    color: #16054e;
    font-size: 24px;
    margin-bottom: 20px;
  }
}
