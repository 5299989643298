.container {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 24px;
        color: #8b82a7;
        font-weight: 500;
      }
      h1 {
        font-size: 24px;
        font-weight: 500;
      }
      img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 210px;
  height: 40px;
  margin-top: 10px;
  margin-right: 30px;
  transition: 0.4s;
  font-weight: 500;
}

.section {
  margin: 20px 0;
  width: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
}

.investor-toggler {
  background: #ffffff;
  padding: 4px;
  // margin-left: 0px;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  padding: 0 30px;
  height: 64px;
}

.nav-link {
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  display: flex;
  padding: 4px 4px;
  transition: 0.3s;
  justify-content: center;
  border-bottom: 4px solid #ffffff;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: #8b82a7;
}

.nav-link:hover {
  color: #8d6cfa;
}

.active-toggle {
  color: #8d6cfa;
  border-color: #8d6cfa;
}
