.modal-container {
  max-height: 80vh;
  overflow-y: scroll;
  &::-webkit-scrollbar{
    display: none;
  }
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card {
  margin-bottom: 30px;
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  padding-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
}

.header h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
  h2 {
    font-size: 18px;
  }
}
