.container {
  background: #ffffff;
  padding: 40px;
}
.header {
  h1 {
    color: #16054e;
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.sections-tab {
  margin-top: 40px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  h1 {
    color: #16054e;
    font-size: 24px;
  }
}
.group-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

.user-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: #16054e;
    font-size: 24px;
  }

  .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.verifier-toggler {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 4px 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-tab {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.button {
  padding: 8px 24px;
  font-size: 15px;
  width: auto;
  margin-right: 10px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 600;
}

.body {
  background: #ffffff;
  margin-top: 16px;
  height: auto;
  padding: 30px;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    border-radius: 100px;
  }
}
.group-table {
  margin-top: 20px;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.modal-container {
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .modal-filter {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
  }
}

.no-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #16054e;
  font-weight: 500;
  font-size: 22px;
  min-height: 350px;
}

.no-user {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  img {
    width: 322px;
    height: 230px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  span {
    color: #8b82a7;
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.modal-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  .modal-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 56px;
      height: 56px;
      margin-right: 24px;
      border-radius: 50%;
    }
  }
  .modal-title {
    h3 {
      font-size: 16px;
      color: #16054e;
      font-weight: 500;
    }
    span {
      font-size: 12px;
      color: #8b82a7;
      font-weight: 400;
    }
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.inputWidth {
  width: 520px;
  margin-bottom: 20px;
}
