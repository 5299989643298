.top {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.content {
  background: #fff;
  padding: 30px;
  margin-top: 30px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 30px;
  border-radius: 16px;
  width: 67%;
  padding: 20px;
  justify-content: space-evenly;
  img {
    height: 20px;
    width: 20px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }
  img:hover {
    scale: 1.2;
  }
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}

.width {
  width: 600px;
  margin-bottom: 20px;
}
