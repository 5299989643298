.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    border-radius: 100px;
  }
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  h1 {
    font-size: 32px;
    font-weight: 500;
    color: #16054e;
  }
}
.button {
  padding: 8px 24px;
  font-size: 15px;
  width: auto;
  margin-right: 10px;
  height: 40px;
  margin-top: 10px;
  transition: 0.4s;
  font-weight: 600;
}
.table {
  background: #ffffff;
  padding: 24px;
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
.interests {
  width: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  span {
    margin-right: 10px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 24px;
    width: 24x;
    cursor: pointer;
    margin-left: 10px;
  }
}
