.container {
  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
    h2 {
      font-size: 30px;
    }
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.content {
  padding: 20px;
  margin-top: 30px;
  background-color: #ffffff;
  h1 {
    margin-bottom: 20px;
  }
}

.alignment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

.audience {
  margin-right: 50px;
  span {
    margin-right: 10px;
    color: #16054e;
    font-weight: 500;
    font-size: 16px;
  }
}

.audience-grid {
  display: flex;
  flex-direction: row;
}

.image-wrapper {
  margin: 10px 0;
}

.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
