@import '../../globalstyles/variables';

.menu {
  width: 100%;

  > button {
    width: 100%;
    display: flex;
    align-items: center;
    color: $fade;
    font-size: 14px;
    padding: 15px 10px;
    background: transparent;

    &.active {
      background: $primary;
      color: $secondary;
      border-radius: 8px;
      font-weight: 700;

      svg {
        path {
          fill: $secondary;
        }
      }
    }

    svg {
      margin-right: 19px;
      height: 20px;
      width: 20px;
    }

    div {
      margin-left: auto;

      i {
        transition: all 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
      }
    }
  }

  .menuItems {
    width: 100%;
    max-height: 0px;
    padding: 0 15px;
    overflow: hidden;
    visibility: visible;
    opacity: 1;
    pointer-events: visible;
    transition: max-height 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.show {
      max-height: 600px;
      overflow-y: auto;
    }
  }
}
