.form-item {
  width: 500px;
  margin-bottom: 25px;
}
.container {
  background: #fff;
  padding: 30px;
  h1 {
    margin-bottom: 30px;
  }
}
.delete-button {
  color: red !important;
  padding: 10px 20px;
  font-size: 15px;
  width: 200px;
  height: 50px;
  transition: 0.4s;
  border-color: red;
  font-weight: 500;
  margin: 20px;
}
.delete-button:hover {
  background-color: red;
  color: white !important;
}
.c-image-uploaded {
  width: 240px;
  height: auto;
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-company-upload {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ede9fa;
  border-radius: 16px;
  div {
    width: 60%;
    font-size: 16px;
    text-align: center;
    color: #16054e;
    font-weight: 400;
    margin-top: 16px;
    span {
      color: #8b82a7;
    }
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h5 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
  }
}
.card-item {
  width: 300px;
}
.form {
  margin: 24px 0 0 0;
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.card_form {
  margin: 24px 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.field-width {
  width: 350px;
  margin-bottom: 25px;
}
.card-container {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 30px;
  border-radius: 16px;
  justify-content: space-between;
  max-width: 750px;
  padding: 20px;
}
.delete-icon {
  cursor: pointer;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.left-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h2 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}
.venture-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  min-height: 500px;
  margin-top: 40px;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
