.container {
  width: 470px;
  height: 190px;
  padding: 15px;
  border: 1px solid #ded7f6;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  flex-direction: row;
  .image {
    img {
      width: 160px;
      height: 160px;
      border-radius: 8px;
    }
  }
  .content {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;

    h1 {
      font-size: 21px;
      min-height: 60px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #16054e;
    }
    span {
      font-size: 14px;
      color: #8b82a7;
    }
    .footer {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      width: 150px;
      justify-content: space-between;
      align-items: center;
      .action-icon {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        img {
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .action-icon:hover {
        opacity: 0.8;
      }
    }
  }
}
