.startup-table {
    width: 90%;
    margin-top: 20px;
    min-height: 600px;
    margin-left: 20px;
  }
  
  .record-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 32px;
      width: 32px;
      margin-right: 10px;
    }
  }
  
  .modal-container {
    h1 {
      margin-bottom: 20px;
    }
  }
  
  .table-container {
    background: #ffffff;
    width: 100%;
    margin-top: 30px;
    .header {
      padding: 20px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 24px;
      }
    }
  }
  .button-wrapper {
    margin-right: 20px;
  }
  .button {
    padding: 10px 25px;
    font-size: 15px;
    width: 200px;
    height: 40px;
    margin-top: 10px;
    transition: 0.4s;
    font-weight: 500;
  }
  .no-updates {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    h1 {
      color: black;
      font-size: 20px;
      font-weight: 600;
    }
    img {
      width: 327px;
      height: 230px;
    }
    span {
      text-align: center;
      margin: 20px 0;
      width: 50%;
    }
  }
  
  .emoji{
    height: 24px;
    width: 24px;
  }
  .add-single {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    align-items: center;
    .image-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        .header {
          font-size: 18px;
          margin-bottom: 2px;
        }
        span {
          color: #8b82a7;
        }
      }
  
      img {
        width: 50px;
        margin-right: 20px;
        height: 50px;
      }
    }
  }
  
  .page-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    span {
      margin-bottom: 10px;
      text-align: center;
      width: 80%;
      color: #8b82a7;
    }
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    margin-top: 259px;
    justify-content: center;
    align-items: center;
  }
  
  .modal-loading {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
  }
  
  .startup-max-height {
    max-height: 600px;
    min-height: 600px;
    overflow-y: scroll;
  }
  