.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-card-comp {
  width: 260px;
  border: 8px solid #ffffff;
  border-radius: 8px;
  height: 112px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  justify-content: center;
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 700;
  }
  span {
    color: #8b82a7;
    font-size: 14px;
    font-weight: 400;
  }
}

.button {
  font-weight: 600;
  margin-right: 10px;
  transition: 0.3s;
  font-size: 14px;
}

.header-cards {
  display: flex;
  margin: 30px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-size: 32px;
  font-weight: 500;
  color: #16054e;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.body {
  background: #ffffff;
  padding: 40px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
}
.plan-grid {
  display: flex;
  flex-direction: row;
}

.tab {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 4px 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-tab {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.sections-tab {
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
}
