.currenciesContainer {
  display: flex;
  background: #fff;
  padding: 20px 35px;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  margin: 20px 0;
  .currency {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      color: #8d94a7;
      font-size: 14px;
      line-height: 20px;
    }
    p {
      margin: 0;
      margin-top: 5px;
      padding: 5px 10px;
      font-weight: 500;
      font-size: 14px;
    }
    .coinImage {
      background-color: #f6f4ff;
      padding: 15px;
      border-radius: 50%;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.savingType {
  background-color: #f6f4ff;
  border-radius: 24px;
}

.savingStatus {
  background-color: #edffe9;
  border-radius: 24px;
}

.header {
  font-size: 20px;
  margin: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.percentages {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin: 20px 0;
  .percentage {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    flex: 1;
    padding: 10px 20px;
    span {
      color: #8d94a7;
      font-size: 12px;
      line-height: 20px;
    }
    p {
      margin: 0;
      margin-top: 5px;
      font-weight: 500;
      font-size: 25px;
    }
    .percentImage {
      img {
        border-radius: 10px;
      }
    }
    .percentageLabel {
    }
  }
}

.tableContainer {
  h1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 50px;
  }
}

.imageProfile {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.dummyAvatar {
  width: 34px; /* Adjust as needed */
  height: 34px; /* Adjust as needed */
  border-radius: 50%;
  background-color: #f3f0fe; /* Fallback color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #5e4cc9;
  text-transform: uppercase;
  padding: 12px;
}

.rootProduct {
  h1 {
    font-size: 22px;
  }
}
