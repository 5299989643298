.investor-container {
    padding: 10px;
    .header {
      margin-top: 10px;
    }
  }
  
  .section {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .investor-toggler {
    background: #ffffff;
    padding: 4px;
    // margin-left: 0px;
    width: 100%;
    display: flex;
    padding: 0 30px;
    height: 64px;
  }
  
  .nav-link {
    font-size: 14px;
    line-height: 24px;
    margin-right: 10px;
    display: flex;
    padding: 4px 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  
  .nav-link:hover {
    color: #8d6cfa;
  }
  
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
  