@import "globalstyles/variables";
.rounded-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .fill {
    stroke: white;

    &:hover {
      stroke: $primary;
    }
    background: $primary;
    color: $secondary;
    border: 1px solid;
    &:hover {
      background-color: $secondary;
      color: $primary;
    }
  }

  .outline {
    stroke: $primary;

    &:hover {
      stroke: white;
    }
    background: transparent;
    color: $primary;
    border: 1px solid;
    &:hover {
      background: $primary;
      color: $secondary;
    }
  }

  button {
    width: 100%;
    height: 60px;
    overflow: hidden;
    padding: 16px 64px;
    border-radius: 32px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
}
.disabled {
  button {
    &:hover {
      cursor: not-allowed;
    }
  }
  opacity: 0.5;
}
