.record-label {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  img {
    width: 30px;
    margin-right: 10px;
    border-radius: 50px;
    height: 30px;
  }
}

.row-width {
  width: 110px !important;
}

.nav-buttons {
  margin: 30px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 0 30px;
  h1 {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.userDownloadModal {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  h1 {
    margin-bottom: 10px;
  }
  span {
    color: #8b82a7;
    font-size: 15px;
    text-align: center;
  }
  .modalText {
    color: #8d6cfa;
    font-weight: 500;
  }
}
