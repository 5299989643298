.no-updates {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  h1 {
    color: black;
    font-size: 20px;
    font-weight: 600;
  }
  img {
    width: 327px;
    height: 230px;
  }
  span {
    text-align: center;
    margin: 20px 0;
    width: 50%;
  }
  .button {
    padding: 8px;
    width: auto;
  }
}
