.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    margin-right: 15px;
    cursor: pointer;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.field-width {
  width: 400px;
  margin-bottom: 30px;
}
.content {
  background: white;
  padding: 30px;
  margin-top: 30px;
}
