.custom-dropdown {
  position: relative;
  width: max-content;
  font-family: Arial, sans-serif;
}

.dropdown-header {
  padding: 10px 20px;
  border: 1px solid #8d6cfa;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-header.open {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.dropdown-options {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  opacity: 0.8;
  z-index: 1000;
  border-top: none;
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background-color: #fff;
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-options li:hover {
  background-color: #f0f0f0;
}
