.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.button {
  font-size: 14px;
  font-weight: 500;
  width: 150px;
  transition: 0.3s;
  padding: 0 10px;
  margin-right: 10px;
}
.percentage-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px 0;
  align-items: center;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.overview-revenue {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.notifications {
  width: 30%;
  background: #ffffff;
  overflow: hidden;
  padding: 10px;
  .header {
    margin-bottom: 10px;
    h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #16054e;
    }
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      color: #8d6cfa;
    }
  }
}
.notification {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid #ded7f6;
  margin-bottom: 10px;
  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #16054e;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8b82a7;
  }
}
.revenue {
  background: #ffffff;
  display: flex;
  // overflow-x: scroll;
  width: 80%;
  height: 500px;
  align-content: center;
  position: relative;
}
.revenue-charts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.revenue-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #16054e;
  }
}
.dropdown {
  border: 0.5px solid #90a0c2;
  border-radius: 16px;
  cursor: pointer;
  padding: 5px 10px;
}
