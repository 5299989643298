.container {
  background: #ffff;
  padding: 30px;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  img {
    margin-right: 10px;
    width: 15px;
    cursor: pointer;
  }
}

.opt-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.opt-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.width {
  width: 700px;
  margin-bottom: 40px;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.unit-price {
  display: block;
  margin: 20px 0;

  span {
    font-size: 18px;
    display: block;
    padding: 5px 0;
    color: black;
  }
}
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 70%;
  margin: 20px 0;
}

.table-body,
.table-head {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-row:nth-child(even) {
  background-color: #dddddd;
}
