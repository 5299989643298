.modal-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 57px;

	.illustration {
		width: 200px;
		height: 200px;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	.heading-text {
		color: #16054e;
		font-size: 24px;
		font-weight: 600;
		margin-top: 40px;
		text-align: center;
	}

	> p {
		color: #8b82a7;
		font-size: 14px;
		text-align: center;
		line-height: 24px;
		margin-top: 16px;
		max-width: 327px;
	}

	.modal-footer {
		display: flex;
		justify-content: space-between;
		margin-top: 64px;
		width: 100%;

		> button {
			width: 50%;
			height: 56px;
			font-weight: 600;
			font-size: 14px;
			border: 1px solid #ee0020;

			> span {
				margin-right: 0px;
			}
		}

		.cancel-button {
			background-color: transparent;
			color: #ee0020;
		}

		.proceed-button {
			background-color: #ee0020;
			color: #fff;
			margin-left: 22px;
		}
	}
}
