.container {
  background: #ffffff;
  // margin-top: 20px;
  padding: 20px;
}

.header {
  color: #16054e;
  font-size: 24px;
  font-weight: 500;
}

.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 210px;
  height: 40px;
  margin-top: 10px;
  margin-right: 30px;
  transition: 0.4s;
  font-weight: 500;
}

.invest-form {
  margin-top: 40px;
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.checker{
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}
span{

}
