.row-dragging {
    display: flex;
    justify-content: space-evenly;
    background: #fafafa;
    color: #16054e;
    font-weight: 800;
  }
  
  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .record-label img{
      width: 32;
      height: 32;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }

  .drag-visible img{
      width: 100px;
      height: 100px;
  }