.count-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-chart {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chart-section {
  width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bullet-point {
  font-size: 18px;
}
.modal-chart-title {
  margin-bottom: 40px;
}
.chart-title {
  margin: 20px 0;
}
.user-chart-container {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: center;
  flex-direction: column;
}

.modal-line-chart {
  position: relative;
  width: 1000px;
  height: 550px;
  display: flex;
  flex-direction: row-reverse;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.modal-bar-chart {
  height: 550px;
}

.modal-line-chart canvas {
  min-width: 1200px;
  height: 500px !important;
}
.bar-chart canvas {
  max-width: 800px !important;
  height: 350px !important;
}

.bar-chart {
  max-width: 800px !important;
  height: 350px;
}

.modal-bar-chart canvas {
  min-width: 1200px;
  height: 500px !important;
}

.line-chart {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 800px;
  height: 350px;
}

.line-chart canvas {
  min-width: 1200px !important;
  height: 250px !important;
}

.lineChart-cotrol-wrap {
  display: flex;
  justify-content: space-between;
}

.lineChart-control {
  cursor: pointer;
  color: #8b82a7;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: center;
  font-size: 12px;
  border-radius: 16px;
  height: 24px;
  padding: 20px;
  margin-right: 24px;
}

.lineChart-control.active {
  background: #8d6cfa;
  color: #ffffff;
  border: 0;
}
