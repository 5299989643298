.container {
  margin-top: 56px;
  background-color: #fff;

  .accordion-header {
    padding: 0px;
    display: flex;
    align-items: center;
    height: 37px;

    .heading-text-wrapper {
      position: relative;
      min-width: 1px;
      max-width: 400px;
      overflow: hidden;
      height: 100%;
    }

    .hidden-span {
      font-size: 24px;
      font-weight: 500;
      opacity: 0;
      white-space: pre;
    }

    input {
      border: none;
      font-size: 24px;
      font-weight: 500;
      width: 100%;
      padding: 0px;
      border-radius: 0px;
      outline: none;
      transition: none;
      position: absolute;
      vertical-align: top;
      top: 0;
      left: 0;
      height: 100%;
      background-color: transparent;

      &::placeholder {
        color: #16054e;
      }
    }

    > label {
      padding: 0px;
      background-color: transparent;
      margin-left: 27px;
      cursor: pointer;
    }

    .accordion-delete-button {
      background-color: transparent;
      padding: 0px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 23px;

      &:hover {
        opacity: 0.7;
      }

      > svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .quill-container {
    margin-top: 0px;
  }

  .quill {
    width: 100%;
    min-height: 240px !important;
    background-color: #f8f8fd;
    display: flex;
    flex-direction: column;

    :global {
      .ql-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .ql-editor {
          flex: 1;
        }
      }
    }
  }
}
