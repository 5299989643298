.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .form {
    margin: 30px 0;
    width: 80%;
  }
}
.switch {
  margin: 10px 0;
}
.allData {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.subtext {
  color: #fa2020;
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
}

.display {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.card {
  width: 392px;
  background: rgba(141, 108, 250, 0.2);
  height: 203px;
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 80% */
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
}

.dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
