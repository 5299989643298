.tags-modal-container {
	:global {
		.db-btn-icon {
			height: 100px;
			margin-right: 0px;
		}
	}

	> h2 {
		font-size: 24px;
		color: #16054e;
		font-weight: 600;
	}

	.search-tags {
		margin-top: 24px;

		> input {
			background-color: transparent;
		}
	}

	.tags-list-container {
		margin-top: 32px;
		height: 300px;
		overflow-y: scroll;

		.add-tags-list {
			display: flex;
			align-items: center;
			margin-bottom: 32px;

			> img {
				width: 56px;
				height: 56px;
				border-radius: 50%;
			}

			.tag-name {
				margin-left: 24px;
				font-size: 16px;
				color: #16054e;
				font-weight: 500;
			}

			.modal-add-button {
				width: 100px;
				height: 32px;
				margin-left: auto;
				font-size: 12px;
				font-weight: 600;
			}
		}
	}

	.tags-modal-footer {
		padding: 24px 40px 0px;
		display: flex;
		align-items: center;
		justify-content: center;

		.modal-continue-button {
			width: 100%;
			height: 56px;
			font-size: 14px;
			font-weight: 600;
		}
	}
}
.loading {
	display: flex;
	flex-direction: column;
	margin: 100px auto;
	justify-content: center;
	align-items: center;
  }
