@import '../../../globalstyles/variables';

.nav {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 32px;
  align-items: center;
}

.search {
  flex: 1;
}
.wrapper {
  margin-top: 20px;
}

.icons {
  width: 18.18px;
  height: 20px;
  cursor: pointer;
}

.dropdownNav {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $dark;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
