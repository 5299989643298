.container-slider {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.slide {
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 1s;
}

.img-container {
  display: flex;
  justify-content: center;
}
.active-anim {
  opacity: 1;
}

.container-dots {
  display: flex;
}
.container-dots-main {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  background: #b9b4ca;
  &:hover {
    cursor: pointer;
  }
}
.dot.active {
  background: #8d6cfa;
}
.community-section {
  text-align: center;
  h1 {
    margin-top: 40px;
    color: #16054e;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #8b82a7;
  }
}
