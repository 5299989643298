.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 15px;
    width: 24px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.al-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  .delete-icon {
    margin-left: 20px;
    height: 25px;
    width: 25px;
  }
}

.body {
  padding: 40px;
}
.title {
  font-size: 32px;
  color: #16054e;
  font-weight: 500;
  margin-bottom: 24px;
}
.container {
  background: #ffffff;
  padding: 5px 0 30px 30px;
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
