.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
}

.content {
  background-color: #fff;
  margin-top: 30px;
  padding: 40px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}

.field {
  width: 600px;
  margin-bottom: 20px;
}

.pdf-height {
  margin: 40px 0;
  max-height: 900px;
  overflow-y: scroll;
}

.field-width {
  width: 700px;
  margin-bottom: 25px;
}

.date {
  width: 300px;
  margin-bottom: 15px;
}

.button {
  transition: 0.3s ease-in-out;
  width: auto;
  padding: 0 10px;
  margin: 20px;
}
.dates {
  display: flex;
  gap: 20px;
}
