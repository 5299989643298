.social-container {
  margin: 30px;
  background-color: white;
  padding: 40px;
  h1 {
    margin-bottom: 20px;
  }
  label {
    font-size: 18px;
  }
  .form-inputs {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.button-style {
  padding: 10px 25px;
  font-size: 15px;
  width: 250px;
  height: 60px;
  margin-top: 10px;
  margin-right: 30px;
  transition: 0.4s;
  font-weight: 500;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: center;
    h1 {
      margin-bottom: 0;
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 15px;
    }
  }
}
