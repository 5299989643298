.tab {
  background: #ffffff;
  padding: 4px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
    display: flex;
    padding: 4px 4px;
    transition: 0.3s;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-tab {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
.sections-tab {
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
}

.render {
  background-color: #fff;
  padding: 40px;
}

.width {
  width: 500px;
  margin-bottom: 10px;
}

.trade {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  h2 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  .userWidth {
    width: 450px;
  }
}

.fees {
  max-width: 450px;
  h3 {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 40px;
  }
}
.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: max-content;
  margin-bottom: 20px;
  margin-left: 25px;
  padding: 5px;
  border-radius: 8px;
  h5 {
    margin-left: 5px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  img {
    cursor: pointer;
    margin-right: 10px;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 30px;
    margin-right: 10px;
    border-radius: 50px;
    height: 30px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.success {
  background-color: #f6f6f9;
  color: #5c5083;
}

.success,
.funded,
.voided,
.pending,
.failed {
  padding: 10px 15px;
  border-radius: 16px;
  font-size: 10px;
}

.failed {
  background: #ebe6e5c7;
  color: #fd3131;
  font-weight: 500;
}

.voided {
  background: #d8bd339a;
  color: #573f0c;
}

.pending {
  background: #dbe9b6d3;
  color: #64740c;
}
.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.userTable {
  overflow-x: scroll;
}
.row-width {
  width: 110px !important;
}
.table {
  background: white;
}
.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-bottom: 20px;
  }
}

.modal-image {
  max-height: 700px;
  max-width: 500px;
}

.method-width {
  cursor: pointer;
}

.method-width:hover {
  color: #8d6cfa;
}

.funded {
  background-color: #1be372;
}
.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  width: 100%;
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}
