.container {
  background: #ffffff;
  padding: 5px 0 20px 50px;
}
.header {
  flex-direction: row;
  align-items: center;
  display: flex;
  img {
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
  }
  h1 {
    color: #16054e;
    font-size: 24px;
    font-weight: 500;
  }
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.key-facts {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 30px 0;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  img {
    margin-left: 15px;
    height: 24px;
    width: 24px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}
.title {
  color: #16054e;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 16px;
}
.mission {
  margin-top: 56px;
}
.overview-header {
  font-size: 32px;
  font-weight: 500;
  color: #16054e;
  margin: 40px 0;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}

.upload-title {
  font-size: 18px;
  margin: 20px;
}

.delete-button {
  margin-top: 20px;
}

.upload-video {
  max-width: 530px;
  min-height: 250px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #16054e;
  }
}

.upload {
  max-width: 400px;
  min-height: 200px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #16054e;
  }
}
.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-file {
  visibility: hidden;
}
.form {
  margin-top: 30px;
}
.warning {
  color: red;
  padding-left: 10px;
  padding-top: 10px;
}
.audio-file {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  audio {
    margin-bottom: 10px;
  }
}
.uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.width {
  width: 700px;
  margin-bottom: 20px;
}
