.root {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.section {
  margin-bottom: 40px;
  h1 {
    color: #16054e;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }
  h2 {
    color: #16054e;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.sectionType {
  display: flex;
  p {
    margin-right: 30px;
    color: #16054e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-top: 4px;
  }
}
.noCurrency {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  p {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 50px;
  }
}

.sectionHeader {
}
.breakdown {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 10px;

  width: 100%;
}

.fees {
  display: grid;
  border-radius: 14px;
  margin-bottom: 20px;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-gap: 10px;
  padding: 10px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: 80%;
  img {
    margin-left: 120px;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.5;
  }
}

.add-button {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: 150px;
  align-items: center;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
  img {
    margin-right: 10px;
  }
  :hover {
    opacity: 0.5;
  }
}
