.navigation {
    display: flex;
    align-items: center;
    flex-direction: row;
    img {
      margin-right: 30px;
      cursor: pointer;
    }
    span {
      font-size: 28px;
      font-weight: 500;
      color: #8b82a7;
      .h-style {
        color: #16054e;
      }
    }
  }
  .upload-image {
    margin: 20px 0;
    h2{
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  .tabs {
    max-width: 730px;
    margin: 40px 0;
  }
  .category {
    margin-top: 20px;
  }
  
  .date-format {
    justify-content: space-between;
    width: 67%;
    display: flex;
    flex-direction: row;
  }
  
  .content {
    background-color: #ffffff;
    padding: 20px;
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  
  .content {
    padding: 40px;
    margin-top: 30px;
    background-color: #ffffff;
    .image-wrapper {
      h1 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      img {
        width: 796px;
        height: 320px;
        margin-bottom: 20px;
      }
    }
  }
  .form {
    margin-top: 40px;
  }
  
  .field {
    margin-top: 20px;
    width: 80%;
  }
  
  .button {
    width: fit-content !important;
    padding: 5px 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .related-topics {
    margin-top: 50px;
    h1 {
      font-size: 24px;
    }
  }
  
  .takeaway-container {
    margin-top: 50px;
    h1 {
      font-size: 24px;
    }
    .take-away {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        margin-left: 5px;
        cursor: pointer;
        margin-bottom: 5px;
      }
    }
  }
  .updateButton {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    button {
      width: max-content;
      font-size: 16px;
      height: 40px;
      padding: 0 20px;
      border-radius: 12px;
      transition: 0.3s ease-in-out;
      margin-bottom: 20px;
    }
  }
  
  .takeaway-list {
    width: 70%;
    background-color: #f8f8fd;
    border: 1px solid #ded7f6;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 4px;
    height: 30px;
    margin-bottom: 10px;
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    margin-top: 259px;
    justify-content: center;
    align-items: center;
  }

  .addSection{
    background-color: transparent;
    border: none;
  }
  .modal-title{
    font-size: 24px;
    color: #16054E;
    font-weight: 600;
  }

  .quill {
    width: 730px;
    min-height: 100px !important;
    margin-top: 8px;
  }
  
  .all-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  
  .quill-container {
    margin: 30px 0;
  }

  .input-file {
    visibility: hidden;
  }
  .video-upload {
    margin-bottom: 30px;
  }
  .upload {
    max-width: 730px;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: #ede9fa;
    border-radius: 16px;
    span {
      font-size: 20px;
      font-weight: 500;
      color: #16054e;
    }
  }
  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pdf-file {
    margin-bottom: 20px;
  }

  .delete-button {
    margin-top: 20px;
    color: red;
    background: none;
    width: 170px;
    height: 40px;
    font-weight: 500;
    border: 1px solid red;
    transition: ease-in-out 0.4s;
    margin-left: 10px;
    font-size: 14px;
  }
  
  .delete-button:hover {
    color: white;
    background-color: red;
    border: none;
  }
  .note{
    padding-left: 10px;
    color: #16054e;
  }
  