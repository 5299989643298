.form {
  display: flex;
  flex-direction: row;
  width: 75%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header {
  margin: 40px 0;
  h1 {
    color: #16054e;
    font-size: 32px;
    font-weight: 500;
  }
}
.title {
  color: #16054e;
  font-size: 21px;
  margin-bottom: 28px;
  font-weight: 500;
}
.overview {
  margin-bottom: 56px;
}

.container {
  background: #ffffff;
  padding: 30px;
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
