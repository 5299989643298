.investor-container {
  padding: 10px;
  .header {
    margin-top: 10px;
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .headerTitle {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
}

.button {
  margin: 15px 0px;
}
.form {
  width: 100%;
}
.halfWidth {
  width: 350px;
  margin-bottom: 20px;
}
.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    .icon {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #f2eeff;
      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
      }
    }
    .selected {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #d0d0ff;
      border: 1px solid #7879f1;
      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
      }
    }
  }
}
.backButton {
  display: flex;
  column-gap: 10px;
  font-size: 24px;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}
.date-format {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 15px;
  .publish {
    margin-right: 10px;
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-component {
  display: flex;
  column-gap: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
}
.tab-content {
  padding: 20px 40px;
  width: 100%;
}
.video-upload {
  margin-bottom: 30px;
}
.upload {
  max-width: 730px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ede9fa;
  border-radius: 16px;
  span {
    font-size: 20px;
    font-weight: 500;
    color: #16054e;
  }
}
.field {
  margin-bottom: 25px;
  width: 700px;
}
.pdf-file {
  font-size: 20px;
  margin-bottom: 20px;
}
.updateButton {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  button {
    width: max-content;
    font-size: 16px;
    height: 40px;
    padding: 0 10px;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
  }
}
.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  column-gap: 5px;
  h1 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
  }
}
.iconForm {
  max-height: 500px;
  overflow-y: scroll;
}
.content {
  display: flex;
  justify-content: space-between;
  align-self: start;
  width: 100%;
  margin-top: 20px;
  .deleteIcon {
    margin-left: 30px;
    height: 24px;
    width: 24px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}
.key-facts {
  width: 100%;
  margin: 30px 0;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  .panel {
    width: 100%;
    .content {
      display: flex;
      justify-content: space-between;
      align-self: start;
      width: 100%;
      div {
        width: 100%;
      }
      img {
        margin-left: 30px;
        height: 24px;
        width: 24px;
        margin-bottom: 30px;
        cursor: pointer;
      }
    }
  }
}

.input-file {
  visibility: hidden;
}
.delete-button {
  margin-top: 20px;
  color: red;
  background: none;
  width: 170px;
  height: 40px;
  font-weight: 500;
  border: 1px solid red;
  transition: ease-in-out 0.4s;
  margin-left: 10px;
  font-size: 14px;
}
.details-toggler {
  padding: 25px 4px;
  width: 20%;
  display: flex;
  flex-direction: column;
  height: 99%;
  margin-left: 20px;
  border-right: 1px solid #f2f2f2;
  row-gap: 20px;

  > button {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-right: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    background: none;
    padding: 4px;
    // margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.percentage-tab {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 40px 0;
  gap: 20px;
  .percentage-card {
    width: 30%;
  }
}

.section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.investor-toggler {
  background: #ffffff;
  padding: 4px;
  // margin-left: 0px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 64px;
}

.nav-link {
  font-size: 14px;
  line-height: 24px;
  margin-right: 10px;
  display: flex;
  padding: 4px 4px;
  transition: 0.3s;
  justify-content: center;
  border-bottom: 4px solid #ffffff;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: #8b82a7;
}

.nav-link:hover {
  color: #8d6cfa;
}

.active-toggle {
  color: #8d6cfa;
  border-color: #8d6cfa;
}

.panel {
  margin-bottom: 20px;
}

.companies {
  max-height: 700px;
  overflow: auto;
  margin: 30px 0;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 24px;
  padding: 10px;
}
.addCompany {
  color: #8d6cfa;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #ccc;
  width: max-content;
  padding: 10px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    color: #fff;
    border-color: none;
    background: #8d6cfa;
  }
}

.company {
  display: flex;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  border-bottom: 1px solid #ccc;

  p {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .companyNumbers {
    display: flex;
    justify-content: space-between;
    .number {
      background: #f8f8fd;
      width: 180px;
      border-radius: 8px;
    }
  }

  gap: 20px;
  .companySelect {
    width: 600px;
  }
  .trash {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
