.top {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    width: max-content;
    padding: 0 10px;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
}

.imageProfile {
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
}
