.root {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
  h1 {
    color: #16054e;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.tableContainer {
  background: #fff;
}

.table {
  min-height: 800px;
  overflow-x: scroll;
}

.contents {
  margin-top: 30px;
  background-color: white;
  padding: 15px;
}

.reference {
  width: 120px;
  font-weight: 500;
  &:hover {
    color: #8d6cfa;
    cursor: pointer;
  }
}

.tableRow {
  cursor: pointer;
}

.filterSection {
  display: flex;
  position: relative;
  align-items: flex-start;
  .txButton {
    width: max-content;
    padding: 0 10px;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .filter {
    margin-left: 20px;
    display: flex;
    padding: 8px 16px !important;
    height: 50px;
    cursor: pointer;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;
    transition: 0.5s ease-in-out;
    border: 1px solid #ded7f6;
    border-radius: 32px;
    align-items: center;
    h2 {
      color: #16054e;
      font-size: 14px;
      font-style: normal;
      margin: 0;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
  .filter:hover {
    opacity: 0.5;
  }
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
